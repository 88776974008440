import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { loginType } from './Types'
import { sendPayload, sendPayloadFailure } from './helper'
const { REQUEST, UPLOAD_IMAGE } = actionTypes

//write sagas function

function* uploadImage(data: loginType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.uploadImage,
      data.data,
    )
    yield sendPayload(apiResponse, UPLOAD_IMAGE)
  } catch (e) {
    yield sendPayloadFailure(e, UPLOAD_IMAGE)
  }
}

export const sagas = {
  //watcher come here
  watchUploadImage: takeLatest(UPLOAD_IMAGE[REQUEST], uploadImage),
}
