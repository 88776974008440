import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import {
  FORGOT_PASSWORD,
  GET_ACCESS_TOKEN,
  GET_CLIENT_KEYS,
  LOGOUT,
  RESET_PASSWORD,
} from '../actions/actiontypes'
import * as ApiService from '../services'
import { ClientKeysTypeSaga, KeyStringValueStringSaga, loginType, refreshType } from './Types'
import { sendPayload, sendPayloadFailure } from './helper'
const { REQUEST, LOGIN } = actionTypes
const refreshTokenErrorCount = { count: 0 }

//write sagas function

function* handleLoginUser(data: loginType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.loginUserEndpoint,
      data.data,
    )
    yield sendPayload(apiResponse, LOGIN)
  } catch (e) {
    yield sendPayloadFailure(e, LOGIN)
  }
}

function* handleLogoutUser(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.logoutUserEndpoint)
    yield sendPayload(apiResponse, LOGOUT)
  } catch (e) {
    yield sendPayloadFailure(e, LOGOUT)
  }
}

function* handleGetAccessToken(data: refreshType): Generator<any, void, any> {
  try {
    if (refreshTokenErrorCount.count < 2) {
      // const { grant_type, refresh_token } = data.data
      const apiResponse: Generator<string, number, string> = yield call(
        ApiService.getAccessTokenEndPoint,
        data.data,
      )
      yield sendPayload(apiResponse, GET_ACCESS_TOKEN)
      refreshTokenErrorCount.count = 0
    } else if (refreshTokenErrorCount.count === 2) {
      refreshTokenErrorCount.count++
      yield call(data.data.userData.navigateTologin)
      return
    }
  } catch (e) {
    if (refreshTokenErrorCount.count < 2) {
      refreshTokenErrorCount.count++
      yield call(handleGetAccessToken, data)
    }
    yield sendPayloadFailure(e, GET_ACCESS_TOKEN)
  }
}

function* handleGetClientKeys(data: ClientKeysTypeSaga) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getClientKeys,
      data.data,
    )
    yield sendPayload(apiResponse, GET_CLIENT_KEYS)
  } catch (e) {
    yield sendPayloadFailure(e, GET_CLIENT_KEYS)
  }
}

function* handleForgotPassword(data: KeyStringValueStringSaga) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.forgotPassword,
      data.data,
    )
    yield sendPayload(apiResponse, FORGOT_PASSWORD)
  } catch (e) {
    yield sendPayloadFailure(e, FORGOT_PASSWORD)
  }
}

function* handleResetPassword(data: KeyStringValueStringSaga) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.resetPassword,
      data.data,
    )
    yield sendPayload(apiResponse, RESET_PASSWORD)
  } catch (e) {
    yield sendPayloadFailure(e, RESET_PASSWORD)
  }
}

export const sagas = {
  //watcher come here
  watchLoginUser: takeLatest(actionTypes.LOGIN[REQUEST], handleLoginUser),
  watchHandleAccessToken: takeLatest(actionTypes.GET_ACCESS_TOKEN[REQUEST], handleGetAccessToken),
  watchLogout: takeLatest(actionTypes.LOGOUT[REQUEST], handleLogoutUser),
  watchClientKeys: takeLatest(actionTypes.GET_CLIENT_KEYS[REQUEST], handleGetClientKeys),
  watchForgotPassword: takeLatest(actionTypes.FORGOT_PASSWORD[REQUEST], handleForgotPassword),
  watchResetPassword: takeLatest(actionTypes.RESET_PASSWORD[REQUEST], handleResetPassword),
}
