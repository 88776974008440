import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { Provider } from 'react-redux'
import store from './configureStore'
import './i18n/config'
import { ThemeProvider, createTheme } from '@mui/material'
import { Primary } from './utils'

/* eslint-disable */
export const themeBreakpoint = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      // small
      sm: 600,
      // medium
      md: 900,
      // large
      lg: 1200,
      // extra-large
      xl: 1536,
    },
  },
})

const theme = createTheme({
  palette: {
    primary: {
      main: Primary,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: Primary,
          color: '#FFF',
          fontWeight: 'bold',
          fontSize: 'large',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: Primary,
            color: '#fff',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'normal',
        required: true,
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          [themeBreakpoint.breakpoints.down('xl')]: {
            width: '100%',
          },
          [themeBreakpoint.breakpoints.up('xl')]: {
            width: '100%',
          },
          marginBottom: '16px',
          '& .MuiFormLabel-root.Mui-focused': {
            color: `${Primary} !important`,
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: '#d32f2f !important',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${Primary} !important`,
          },
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d32f2f !important',
          },
          '&:hover fieldset': {
            borderColor: 'grey',
          },
          '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill': {
            'box-shadow': '0 0 0 30px white inset !important',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          [themeBreakpoint.breakpoints.down('xl')]: {
            width: '100%',
          },
          [themeBreakpoint.breakpoints.up('xl')]: {
            width: '100%',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        target: '_blank',
      },
    },
    MuiStack: {
      defaultProps: { direction: 'row', alignItems: 'center' },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: Primary,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 6px #00000029',
          borderRadius: '0px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
)
/* eslint-disable */
