import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import {
  ADD_NEW_USER,
  DELETE_MODULE,
  DELETE_USER_CLIENT,
  DELETE_USER_MODULE,
  DELETE_USER_SUB_MODULE,
  RESEND_RESET_PASSWORD_LINK,
  RESET,
  UPDATE_USER,
} from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, FAILURE, GET_ALL_USER } = actionTypes

const ui = () => {
  const isGetAllUserDone = (state = false, action: actions) => {
    switch (action.type) {
      case GET_ALL_USER[SUCCESS]:
        return true
      case GET_ALL_USER[FAILURE]:
      case GET_ALL_USER[REQUEST]:
        return false
      default:
        return state
    }
  }

  const createUser = (state = [], action: actions) => {
    switch (action.type) {
      case ADD_NEW_USER[SUCCESS]:
        return true
      case ADD_NEW_USER[REQUEST]:
        return []
      case ADD_NEW_USER[RESET]:
        return []
      default:
        return state
    }
  }

  const deleteUser = (state = false, action: actions) => {
    switch (action.type) {
      case DELETE_MODULE[SUCCESS]:
        return true
      case DELETE_MODULE[FAILURE]:
      case DELETE_MODULE[REQUEST]:
        return false
      default:
        return state
    }
  }

  const updateUser = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_USER[SUCCESS]:
        return true
      case UPDATE_USER[REQUEST]:
        return []
      case UPDATE_USER[RESET]:
        return []
      default:
        return state
    }
  }

  const deleteUserModule = (state = false, action: actions) => {
    switch (action.type) {
      case DELETE_USER_MODULE[SUCCESS]:
        return true
      case DELETE_USER_MODULE[FAILURE]:
        return false
      case DELETE_USER_MODULE[REQUEST]:
        return false
      case DELETE_USER_MODULE[RESET]:
        return false
      default:
        return state
    }
  }

  const deleteUserClient = (state = false, action: actions) => {
    switch (action.type) {
      case DELETE_USER_CLIENT[SUCCESS]:
        return true
      case DELETE_USER_CLIENT[FAILURE]:
        return false
      case DELETE_USER_CLIENT[REQUEST]:
        return false
      case DELETE_USER_CLIENT[RESET]:
        return false
      default:
        return state
    }
  }

  const deleteUserSubModule = (state = false, action: actions) => {
    switch (action.type) {
      case DELETE_USER_SUB_MODULE[SUCCESS]:
        return true
      case DELETE_USER_SUB_MODULE[FAILURE]:
        return false
      case DELETE_USER_SUB_MODULE[REQUEST]:
        return false
      case DELETE_USER_SUB_MODULE[RESET]:
        return false
      default:
        return state
    }
  }

  const resendPasswordReset = (state = false, action: actions) => {
    switch (action.type) {
      case RESEND_RESET_PASSWORD_LINK[SUCCESS]:
        return true
      case RESEND_RESET_PASSWORD_LINK[REQUEST]:
        return false
      case RESEND_RESET_PASSWORD_LINK[FAILURE]:
        return false
      case RESEND_RESET_PASSWORD_LINK[RESET]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    isGetAllUserDone,
    createUser,
    updateUser,
    deleteUser,
    deleteUserModule,
    deleteUserClient,
    deleteUserSubModule,
    resendPasswordReset,
  })
}

export default ui

export const getAllUsers = (state: RootState) => state.ui.userManagement
