import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import {
  ADD_NEW_USER,
  DELETE_USER,
  DELETE_USER_CLIENT,
  DELETE_USER_MODULE,
  DELETE_USER_SUB_MODULE,
  GET_ALL_USER,
  GET_USER_BY_ID,
  UPDATE_USER,
  RESEND_RESET_PASSWORD_LINK,
} from '../actions/actiontypes'
import * as ApiService from '../services'
import {
  ResetPasswordTypeSaga,
  UserBasicTypeForSage,
  UserClientDeleteTypeSaga,
  UserDeleteTypeSaga,
  UserModuleDeleteTypeSaga,
  UserSubModuleDeleteTypeSaga,
  UserTypesForSaga,
} from './Types'
import { sendPayload, sendPayloadFailure } from './helper'
const { REQUEST, REFRESH_ACCESS_TOKEN } = actionTypes

//write sagas function

function* handleGetAllUser() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getAllUsers)
    yield sendPayload(apiResponse, GET_ALL_USER)
  } catch (e) {
    yield sendPayloadFailure(e, GET_ALL_USER)
  }
}

function* handleCreateUser(data: UserTypesForSaga) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createUserEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, ADD_NEW_USER)
  } catch (e) {
    yield sendPayloadFailure(e, ADD_NEW_USER)
  }
}

function* handleUpdateUser(data: UserTypesForSaga) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateUserEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_USER)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_USER)
  }
}

function* handleDeleteUser(data: UserDeleteTypeSaga) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteUserEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, DELETE_USER)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_USER)
  }
}

function* handleGetUserById(data: UserBasicTypeForSage) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getUserByIdEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, GET_USER_BY_ID)
  } catch (e) {
    yield sendPayloadFailure(e, GET_USER_BY_ID)
  }
}

function* handleDeleteUserModule(data: UserModuleDeleteTypeSaga) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteUserModule,
      data.data,
    )
    yield sendPayload(apiResponse, DELETE_USER_MODULE)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_USER_MODULE)
  }
}

function* handleDeleteUserClient(data: UserClientDeleteTypeSaga) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteUserClient,
      data.data,
    )
    yield sendPayload(apiResponse, DELETE_USER_CLIENT)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_USER_CLIENT)
  }
}

function* handleDeleteUserSubModule(data: UserSubModuleDeleteTypeSaga) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteUserSubModule,
      data.data,
    )
    yield sendPayload(apiResponse, DELETE_USER_SUB_MODULE)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_USER_SUB_MODULE)
  }
}

function* handleResendPasswordReset(data: ResetPasswordTypeSaga) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.resendPasswordReset,
      data.data,
    )
    yield sendPayload(apiResponse, RESEND_RESET_PASSWORD_LINK)
  } catch (e) {
    yield sendPayloadFailure(e, RESEND_RESET_PASSWORD_LINK)
  }
}


export const sagas = {
  //watcher come here
  watchGetAllUser: takeLatest(GET_ALL_USER[REQUEST], handleGetAllUser),
  createUser: takeLatest(actionTypes.ADD_NEW_USER[REQUEST], handleCreateUser),
  deleteUser: takeLatest(actionTypes.DELETE_USER[REQUEST], handleDeleteUser),
  getUserById: takeLatest(actionTypes.GET_USER_BY_ID[REQUEST], handleGetUserById),
  updateUser: takeLatest(actionTypes.UPDATE_USER[REQUEST], handleUpdateUser),
  deleteUserModule: takeLatest(actionTypes.DELETE_USER_MODULE[REQUEST], handleDeleteUserModule),
  deleteUserClient: takeLatest(actionTypes.DELETE_USER_CLIENT[REQUEST], handleDeleteUserClient),
  deleteUserSubModule: takeLatest(
    actionTypes.DELETE_USER_SUB_MODULE[REQUEST],
    handleDeleteUserSubModule,
  ),
  watchResendPasswordResentLink: takeLatest(RESEND_RESET_PASSWORD_LINK[REQUEST], handleResendPasswordReset),
}
