import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import {
  CREATE_MODULE,
  DELETE_MODULE,
  FAILURE,
  GET_ALL_USER,
  GET_USER_BY_ID,
  SUCCESS,
  UPLOAD_IMAGE,
  GET_CLIENT_FEATURES,
  FORGOT_PASSWORD,
  RESEND_RESET_PASSWORD_LINK,
} from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const {
  REQUEST,
  LOGIN,
  GET_ALL_CLIENTS,
  CREATE_CLIENTS,
  EDIT_CLIENT,
  GET_ALL_MODULES,
  GET_SUB_MODULE,
} = actionTypes

const ui = () => {
  const isLoaderTrue = (state = false, action: actions) => {
    switch (action.type) {
      case LOGIN[REQUEST]:
      case GET_ALL_CLIENTS[REQUEST]:
      case GET_ALL_MODULES[REQUEST]:
      case CREATE_CLIENTS[REQUEST]:
      case EDIT_CLIENT[REQUEST]:
      case GET_SUB_MODULE[REQUEST]:
      case UPLOAD_IMAGE[REQUEST]:
      case GET_ALL_USER[REQUEST]:
      case GET_USER_BY_ID[REQUEST]:
      case DELETE_MODULE[REQUEST]:
      case CREATE_MODULE[REQUEST]:
      case GET_CLIENT_FEATURES[REQUEST]:
      case FORGOT_PASSWORD[REQUEST]:
      case RESEND_RESET_PASSWORD_LINK[REQUEST]:
        return true
      case GET_ALL_USER[SUCCESS]:
      case GET_ALL_USER[FAILURE]:
      case LOGIN[FAILURE]:
      case LOGIN[SUCCESS]:
      case GET_ALL_MODULES[FAILURE]:
      case GET_ALL_MODULES[SUCCESS]:
      case GET_ALL_CLIENTS[SUCCESS]:
      case GET_ALL_CLIENTS[FAILURE]:
      case GET_USER_BY_ID[SUCCESS]:
      case GET_USER_BY_ID[FAILURE]:
      case UPLOAD_IMAGE[SUCCESS]:
      case UPLOAD_IMAGE[FAILURE]:
      case DELETE_MODULE[SUCCESS]:
      case DELETE_MODULE[FAILURE]:
      case CREATE_MODULE[SUCCESS]:
      case CREATE_MODULE[FAILURE]:
      case EDIT_CLIENT[SUCCESS]:
      case EDIT_CLIENT[FAILURE]:
      case CREATE_CLIENTS[SUCCESS]:
      case CREATE_CLIENTS[FAILURE]:
      case GET_CLIENT_FEATURES[SUCCESS]:
      case GET_CLIENT_FEATURES[FAILURE]:
      case FORGOT_PASSWORD[SUCCESS]:
      case FORGOT_PASSWORD[FAILURE]:
      case RESEND_RESET_PASSWORD_LINK[SUCCESS]:
      case RESEND_RESET_PASSWORD_LINK[FAILURE]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    isLoaderTrue,
  })
}

export default ui

export const getLoaderState = (state: RootState) => state.ui.loaderState
