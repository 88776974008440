import { Paper, Typography, Box, Stack, Button } from '@mui/material'
import styled from '@emotion/styled'
import { createTheme } from '@mui/material'
import { Background, Primary } from '../../utils'

//Client Management Styles
const iconTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        startIcon: {
          '& > *:nth-of-type(1)': {
            color: '#FFFFFF',
            fontSize: '22px',
          },
          marginBottom: '1px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiAccordion-root': {
            borderRadius: '0px',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        wrapperInner: {
          width: '98%',
          margin: 'auto',
        },
      },
    },
  },
})

const ClientManagementPaper = styled(Paper)({
  padding: '20px 40px 0px 40px',
  width: '-100%',
  height: '100%',
  boxSizing: 'border-box',
  margin: '20px 12px 10px 12px',
  display: 'grid',
})

const AvatarStyles = (avtarStyle?: { [key: string]: string | number }) => {
  return {
    backgroundColor: '#fff',
    color: Primary,
    boxShadow: '0px 3px 6px #00000029',
    marginRight: '5px',
    ...avtarStyle,
  }
}

const MainContainer = styled(Box)<{ height?: string }>((props) => ({
  margin: '20px 12px 10px 12px',
  width: '98%',
  height: props.height ? props.height : '83%',
  boxShadow: '0px 3px 6px #00000029',
  padding: '20px 40px 30px 40px',
  overflow: 'hidden',
  backgroundColor: '#fff',
  boxSizing: 'border-box',
  display: 'grid',
}))

const StyledHeaderTypography = styled(Typography)({
  fontWeight: '500',
  fontSize: '14px',
})

const StyledBoxHeader = styled(Box)((props) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: Primary,
  borderRadius: '10px',
  height: '50px',
}))

const StyledBoxHeaderCellID = styled(Box)((props) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#FFFFFF',
  justifyContent: 'center',
  width: '12%',
}))

const StyledBoxHeaderCellClientName = styled(Box)((props) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#FFFFFF',
  justifyContent: 'center',
  width: '26%',
}))

const StyledBoxHeaderCellEmailID = styled(Box)((props) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#FFFFFF',
  justifyContent: 'flex-start',
  width: '31%',
  paddingLeft: '5%',
}))

const StyledBoxHeaderCellModuleClient = styled(Box)((props) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#FFFFFF',
  justifyContent: 'center',
  width: '17%',
}))

const StyledBoxHeaderCellAction = styled(Box)((props) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#FFFFFF',
  justifyContent: 'center',
  width: '14.5%',
}))

const StyledMainStack = styled(Stack)({
  alignItems: 'stretch',
  overflow: 'auto',
})

const StyledMainHeaderStack = styled(Stack)({
  // marginBottom: '5px',
  justifyContent: 'flex-start',
})

const StyledMainHeadingStack = styled(Stack)({
  alignItems: 'flex-start',
})

const Heading = () => {
  return { fontWeight: 'bold', fontSize: '18px' }
}

const SubHeading = () => {
  return { color: '#999999', mt: '-7px' }
}

const StyledSecondStack = styled(Stack)({
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px !important',
})

const PaginationStack = () => {
  return {
    justifyContent: 'flex-end',
    mt: 3,
    mb: 1.5,
    mr: '-2.3%',
    width: '100%',
    alignSelf: 'flex-end',
  }
}

const TableDataBox = () => {
  return { height: '300px', overflow: 'auto' }
}

const clientManagementTableStyles = { maxHeight: 'unset' }

//Client Details Styles

const ClientDetailsTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          borderRadius: '9px',
        },
      },
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            'fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: '#868686',
              borderWidth: '1px',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        startIcon: {
          '& > *:nth-of-type(1)': {
            color: '#FFFFFF',
            fontSize: '22px',
          },
        },
      },
    },
  },
})

const ClientDetailsPaper = styled(Paper)({
  padding: '0px 30px 0px 30px',
  width: '93.5%',
  height: '100%',
  margin: '20px 12px 10px 12px',
})

const StyledCancelButton = styled(Button)({
  backgroundColor: 'white',
  fontSize: '14px',
  color: Primary,
  borderRadius: '8px',
  borderColor: Primary,
  textTransform: 'none',
  padding: '5px 10px',
  '&:hover': {
    color: Primary,
    backgroundColor: 'white',
  },
  width: '120px',
})

const StyledAddButton = styled(Button)({
  backgroundColor: Primary,
  fontSize: '14px',
  color: '#FFFFFF',
  borderRadius: '8px',
  borderColor: '#86868633',
  textTransform: 'none',
  padding: '5px 10px',
  width: '120px',
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: '#0000001f',
  },
  '&:hover': {
    color: '#FFFFFF',
    backgroundColor: Primary,
  },
})

const StyledStack = styled(Stack)({
  '& > div': {
    margin: '1rem 1rem 1rem 0.05rem',
  },
  flexWrap: 'wrap',
  overflowY: 'auto',
  maxHeight: '30vh',
  width: '-webkit-fill-available',
  alignContent: 'flex-start',
})

const StylingAsterisk = styled('span')((props: any) => ({
  fontSize: props.fontsize,
  color: 'red',
}))

const ClientInfoLabel = { fontWeight: 450, fontSize: '0.8rem' }

const MainStack = { alignItems: 'flex-start', mt: 1 }

const SubStack1 = { justifyContent: 'flex-start', alignItems: 'flex-start', width: '48%' }

const SubStack1_2 = {
  width: '-webkit-fill-available',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  backgroundColor: '#F8F9FF',
  borderRadius: '9px',
  padding: '20px',
}

const OutlinedInputSX = { fontSize: '15px', width: '100%', backgroundColor: 'white' }

const CompanyLogoStackSX = { alignItems: 'flex-start', width: '-webkit-fill-available' }

const DragDropStack = { width: '-webkit-fill-available' }

const SubModuleCard = { backgroundColor: 'white' }

const AssignedModuleStack = {
  width: '-webkit-fill-available',
  alignItems: 'flex-start',
  backgroundColor: '#F8F9FF',
  borderRadius: '9px',
  padding: '20px',
}

const AdminListMainStack = {
  backgroundColor: '#F8F9FF',
  width: '46%',
  borderRadius: '9px',
  padding: '20px',
}

const DialogStack = { padding: '15px' }

const DialogContentStack = { alignItems: 'flex-start' }

const DialogActionSX = { padding: '0px 20px 24px 0px' }

const CompanyNameStack = { alignItems: 'flex-start', width: '20.6vw' }

const AdminListStack = { justifyContent: 'space-between' }

const AdminTableSX = {
  textAlign: 'left',
  backgroundColor: 'white',
  borderRadius: '9px',
  padding: '15px 0px 15px 25px',
}

const AdminListTableStyles = { maxHeight: '364px' }

//Assigned Modules Styles

const cellBoxSx = (checkbox?: boolean, featureCell?: boolean) => {
  return {
    display: 'flex',
    justifyContent: featureCell ? 'unset' : 'center',
    alignItems: 'center',
    backgroundColor: !checkbox ? Background : 'unset',
    height: '40px',
    position: !checkbox ? 'sticky' : 'unset',
    top: !checkbox ? '40px' : 'unset',
    zIndex: !checkbox ? 5 : 'unset',
  }
}

const checkboxSx = {
  '& svg': {
    fill: Primary,
  },
}

const headerGridBox = (maxLength: number, maxSize: number) => {
  return {
    display: 'grid',
    gridTemplateColumns: `repeat(${maxLength + 1},1fr)`,
    height: '37vh',
    overflowY: 'scroll',
    mt: '15px',
    gridTemplateRows: `40px repeat(${maxSize},45px)`,
  }
}

const headerBoxSx = {
  height: '40px',
  bgcolor: Primary,
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'sticky',
  top: '0px',
  zIndex: 5,
  '& > p': {
    fontWeight: 500,
    fontSize: '14px',
  },
}

const featureHeaderSx = {
  borderTopLeftRadius: '5px',
  borderBottomLeftRadius: '5px',
}

const pesmissionsHeaderSx = { borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }

const checkboxLabelSx = { '& span': { fontSize: '14px' } }

const AssignModulePaper = styled(Paper)({
  padding: '0px 30px 0px 30px',
  width: '93.5%',
  height: '100%',
  margin: '20px 12px 10px 12px',
})

const StyledStackAssignModule = styled(Stack)({
  '& > div': {
    margin: '0 0.5rem 0 0.5rem',
  },
  justifyContent: 'flex-start',
  flexWrap: 'nowrap',
  marginTop: '0.5rem',
  marginLeft: '-0.5rem',
  maxHeight: '63vh',
  overflowX: 'auto',
  width: '100%',
  borderRadius: '9px',
})

const StyledImg = styled('img')({
  width: '30px',
})

const SelectTypography = { fontWeight: 450, fontSize: '1rem' }

const errorSx = { color: 'red', width: '14rem', lineHeight: 1, fontSize: '0.65rem', mt: '3px' }

const paperMessageSx = { padding: '15px 60px 15px 20px', color: '#999999', borderRadius: '9px' }

const alignFlexStart = {
  alignItems: 'flex-start',
  height: '65px',
}

const AssignModuleColumnStackSx = {
  bgcolor: '#F8F9FF',
  alignItems: 'flex-start',
  mt: '15px',
  padding: '10px 20px 10px 20px',
  borderRadius: '9px',
}

const parentCheckBoxSx = {
  color: 'white',
  '&.MuiButtonBase-root.MuiCheckbox-root.Mui-checked': { color: 'white' },
  '&.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate': { color: 'white' },
}

const featureHeaderBoxSx = {
  justifyContent: 'flex-start',
  gap: '12px',
}

export {
  ClientManagementPaper,
  iconTheme,
  StyledHeaderTypography,
  StyledBoxHeader,
  StyledMainStack,
  StyledMainHeaderStack,
  StyledMainHeadingStack,
  StyledSecondStack,
  StyledBoxHeaderCellID,
  StyledBoxHeaderCellClientName,
  StyledBoxHeaderCellEmailID,
  StyledBoxHeaderCellModuleClient,
  StyledBoxHeaderCellAction,
  ClientDetailsPaper,
  StylingAsterisk,
  ClientDetailsTheme,
  StyledStack,
  ClientInfoLabel,
  StyledCancelButton,
  StyledAddButton,
  MainStack,
  SubStack1,
  SubStack1_2,
  OutlinedInputSX,
  CompanyLogoStackSX,
  DragDropStack,
  AssignedModuleStack,
  SubModuleCard,
  AdminListMainStack,
  DialogStack,
  DialogContentStack,
  DialogActionSX,
  CompanyNameStack,
  AdminListStack,
  AdminTableSX,
  AssignModulePaper,
  SelectTypography,
  StyledImg,
  StyledStackAssignModule,
  Heading,
  SubHeading,
  PaginationStack,
  TableDataBox,
  errorSx,
  paperMessageSx,
  MainContainer,
  AdminListTableStyles,
  alignFlexStart,
  AssignModuleColumnStackSx,
  AvatarStyles,
  cellBoxSx,
  checkboxSx,
  featureHeaderSx,
  headerGridBox,
  headerBoxSx,
  pesmissionsHeaderSx,
  checkboxLabelSx,
  parentCheckBoxSx,
  featureHeaderBoxSx,
  clientManagementTableStyles,
}
