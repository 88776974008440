import {
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import LanguageDropdown from '../Common/LanguageDropdown'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { isLoggedIn } from '../../auth/Auth'
import {
  Heading,
  LangDrop,
  LeftContainer,
  LoginButton,
  Logo,
  MainContainer,
  RememberMeOuterDiv,
  RightContainer,
} from './Styles'
import { connect } from 'react-redux'
import { getClientKeys, getUserAuthentication } from '../../actions'
import { Dispatch } from 'redux'
import { loaderStateUI, userAuthenticationEntity, userAuthenticationUI } from '../../reducers'
import { IAuthData } from '../../actions/Types'
import { RootState } from '../../configureStore'
import banner from '../../assets/images/uniqus_bannerv2.png'
import { CursorPointer, loaderProps } from '../Common/CommonStyles'
import { saltHashedPassword } from '../../utils'

interface login {
  fetchUser: (data: IAuthData) => void
  loginStatusPass: boolean
  loginStatusFail: boolean
  loaderState: boolean | undefined
  loginData: { data?: { error_description: string } }
  fetchClientKeys: (data: { email: string }) => void
  clientsKeyData: any
  clientsKeyDone: boolean
  clientsKeyFail: boolean
  resetClientKeys: () => void
}

const Login = (props: login) => {
  const { t } = useTranslation()
  const {
    fetchUser,
    loginStatusPass,
    loginStatusFail,
    loaderState,
    fetchClientKeys,
    clientsKeyData,
    clientsKeyDone,
    clientsKeyFail,
    resetClientKeys,
  } = props
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [validEmailMessage, setValidEmailMessage] = useState('')
  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/module')
      toast.success(t('login_success'))
    }
  }, [loginStatusFail, loginStatusPass])

  useEffect(() => {
    if (clientsKeyDone) {
      localStorage.setItem('username', clientsKeyData.oauthClientId)
      localStorage.setItem('secretKey', clientsKeyData.clientSecret)
      fetchUser({
        userData: { grant_type: 'password', username: email, password: saltHashedPassword(password, email) },
        clientKeys: {
          clientSecret: clientsKeyData.clientSecret,
          oauthClientId: clientsKeyData.oauthClientId,
        },
      })
      resetClientKeys()
    }
  }, [clientsKeyDone, clientsKeyFail])

  //Checking valid email
  const isValidEmail = (email: string): boolean => {
    const emailRegex: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    return emailRegex.test(email)
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value?.trimStart())
  }
  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleSubmit = () => {
    if (email === '' || password === '') {
      toast.error(t('fill_both_username_and_password'))
    } else {
      if (isValidEmail(email)) {
        setValidEmailMessage('')
      } else {
        setValidEmailMessage(`${t('valid_email')}`)
        return
      }
      fetchClientKeys({ email: email })
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleForgotPass = () => {
    navigate('/forgot-password')
  }

  return (
    <MainContainer>
      <LeftContainer src={banner}></LeftContainer>
      <RightContainer>
        <LangDrop>
          <LanguageDropdown />
        </LangDrop>
        <Logo src='./images/logo.png'></Logo>
        <Heading>{t('login')}</Heading>
        <TextField
          id='email'
          label={t('email')}
          name='email'
          autoComplete='email'
          autoFocus
          value={email}
          helperText={validEmailMessage}
          error={validEmailMessage.length > 0}
          onKeyPress={handleKeyPress}
          onChange={handleEmailInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <MailOutlineIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControl variant='outlined'>
          <InputLabel required htmlFor='outlined-adornment-password'>
            {t('password')}
          </InputLabel>
          <OutlinedInput
            id='outlined-adornment-password'
            type={showPassword ? 'text' : 'password'}
            onChange={handlePasswordInput}
            onKeyPress={handleKeyPress}
            value={password}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label='Password'
          />
        </FormControl>
        <RememberMeOuterDiv>
          {' '}
          <FormControlLabel control={<Checkbox />} label={t('remember_me')} />{' '}
          <Link sx={CursorPointer} onClick={handleForgotPass}>
            {t('forgot_password')}
          </Link>
        </RememberMeOuterDiv>
        <LoginButton fullWidth onClick={handleSubmit}>
          {t('login')}
        </LoginButton>{' '}
      </RightContainer>
      {loaderState && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
    </MainContainer>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    loginData: userAuthenticationEntity.getUserAuthentication(state).getUserAuthentication,
    loginStatusPass: userAuthenticationUI.getUserAuthentication(state).isUserAuthenticationDone,
    loginStatusFail: userAuthenticationUI.getUserAuthentication(state).isUserAuthenticationFailed,
    loaderState: loaderStateUI.getLoaderState(state).isLoaderTrue,
    clientsKeyData: userAuthenticationEntity.getUserAuthentication(state).getClientKeys,
    clientsKeyDone: userAuthenticationUI.getUserAuthentication(state).getClientKeys,
    clientsKeyFail: userAuthenticationUI.getUserAuthentication(state).getClientKeys,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUser: (data: IAuthData) => dispatch(getUserAuthentication.request(data)),
    fetchClientKeys: (data: { email: string }) => dispatch(getClientKeys.request(data)),
    resetClientKeys: () => dispatch(getClientKeys.reset()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
