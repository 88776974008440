import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RESET } from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, FAILURE, GET_USER_DATA } = actionTypes

const ui = () => {
  const isGetUserDataDone = (state = false, action: actions) => {
    switch (action.type) {
      case GET_USER_DATA[SUCCESS]:
        return true
      case GET_USER_DATA[FAILURE]:
      case GET_USER_DATA[REQUEST]:
        return false
      case GET_USER_DATA[RESET]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    isGetUserDataDone,
  })
}

export default ui

export const getDashboard = (state: RootState) => state.ui.dashboard
