import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
const { REQUEST, REFRESH_ACCESS_TOKEN } = actionTypes

//write sagas function

function* handleRefreshAccessToken(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.refreshAccessTokenEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, REFRESH_ACCESS_TOKEN)
  } catch (e) {
    yield sendPayloadFailure(e, REFRESH_ACCESS_TOKEN)
  }
}

export const sagas = {
  //watcher come here
  watchRefreshAccessToken: takeLatest(
    actionTypes.REFRESH_ACCESS_TOKEN[REQUEST],
    handleRefreshAccessToken,
  ),
}
