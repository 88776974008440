import React, { useEffect, useState } from 'react'
import {
  AllModuleContainer,
  MainContainer,
  StyledButton,
} from '../Module Management/ModuleManagementComponents'
import StyledBreadCrumb from '../Common/StyledBreadCrumb'
import {
  AddSubModuleCard,
  ColumnStack,
  FullWidthStack,
  MandatoryMark,
  StyledContainedButton,
  StyledLable,
  StyledTextField,
} from '../Common/CommonStyles'
import { Modal, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import StyledTable from '../Common/StyledTable'
import { useTranslation } from 'react-i18next'
import SubModuleCards from '../Common/SubModuleCard'
import { useNavigate } from 'react-router-dom'
import AddClientModal from './AddClientModal'
import {
  AddClientDesc,
  AddUserContainer,
  AddUserInputContainer,
  AddUserSmallInput,
  AddUserTableContainer,
} from './UserManagementStyles'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import {
  addUser,
  deleteUserClient,
  deleteUserModule,
  deleteUserSubModule,
  getUserById,
  resendPasswordReset,
  saveUserDetails,
  updateUser,
} from '../../actions'
import { dashboardEntity, userManagementEntity, userManagementUI } from '../../reducers'
import {
  AssignModuleType,
  AssignSubModuleType,
  ClientDTO,
  GetUserById,
  ModuleDTO,
  NewAddedDataTypes,
  SavedUserDetailsType,
  SubModuleDTO,
  UserClientDeleteType,
  UserDTO,
  UserDTOCreateType,
  UserDTOUpdateType,
  UserModuleDeleteType,
  UserResponseType,
  UserSubModuleDeleteType,
  UserTypes,
} from './UserManagementTypes'
import { toast } from 'react-toastify'

interface AddNewUserProps {
  saveUserDetails: (data: SavedUserDetailsType) => void
  savedUserDetails: SavedUserDetailsType
  createNewUser: (data: UserDTOCreateType) => void
  updateUserDetails: (data: UserDTOUpdateType) => void
  userCreatedStatus: UserResponseType[]
  userUpdateStatus: UserResponseType
  userData: UserDTO
  resetUserDetails: () => void
  resetCreateUser: () => void
  resetUpdateUser: () => void
  getUserById: (data: GetUserById) => void
  searchedUserData: UserResponseType
  deleteUserClient: (data: UserClientDeleteType) => void
  deleteUserModule: (data: UserModuleDeleteType) => void
  deleteUserSubModule: (data: UserSubModuleDeleteType) => void
  deletedModule: boolean
  deletedClient: boolean
  deletedSubModule: boolean
  resetSearchedUser: () => void
  resetDeleteClient: () => void
  resetDeleteModule: () => void
  resetDeleteSubModule: () => void
  resendPasswordReset: (data: object) => void
  resendPassword: boolean
  resetResendPasswordReset:()=>void
}

const AddNewUser = (props: AddNewUserProps) => {
  const {
    saveUserDetails,
    savedUserDetails,
    createNewUser,
    updateUserDetails,
    userCreatedStatus,
    userUpdateStatus,
    resetUserDetails,
    resetCreateUser,
    resetUpdateUser,
    userData,
    getUserById,
    searchedUserData,
    deletedModule,
    deletedClient,
    deletedSubModule,
    deleteUserClient,
    deleteUserModule,
    resetSearchedUser,
    resetDeleteClient,
    resetDeleteModule,
    resetDeleteSubModule,
    deleteUserSubModule,
    resendPasswordReset,
    resendPassword,
    resetResendPasswordReset,
  } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userId = localStorage.getItem('searchedUser')
  const [openModal, setOpenModal] = useState(false)
  const [firstName, setName] = useState('')
  const [designation, setDesignation] = useState('')
  const [email, setEmail] = useState('')
  const [clientList, setClientList] = useState<ClientDTO[]>([])
  const [assignedModule, setAssignedModule] = useState<ModuleDTO[] | []>([])
  const [assignedSubModule, setAssignedSubModule] = useState<SubModuleDTO[] | []>([])
  const [submitClicked, setSubmitClicked] = useState(false)
  const [finalAssignModuleData, setFinalAssignModuleData] = useState<AssignModuleType[]>()
  const [finalAssignSubModuleData, setFinalAssignSubModuleData] = useState<AssignSubModuleType[]>()
  const [lastAction, setLastAction] = useState(savedUserDetails.lastAction ?? '')
  const [dataBeforeDelete, setDataBeforeDelete] = useState<NewAddedDataTypes | null>(null)

  //this useEffect will reset assigned module data after delete form card
  useEffect(() => {
    setName(savedUserDetails.firstName)
    setDesignation(savedUserDetails.designation)
    setEmail(savedUserDetails.email)
    setClientList(savedUserDetails.clientList)
    setAssignedModule(savedUserDetails.assignedModule)
    setAssignedSubModule(savedUserDetails.assignedSubModule)
    handleMakeFinalData()
    handleStoreData()
  }, [savedUserDetails])

  //This function is to store added data before some delete action
  const handleStoreData = () => {
    if (userId) {
      const addedAssignedModule = assignedModule?.filter((module) => module.newlyCreated)
      const addedAssignedSubModule = assignedSubModule?.filter((module) => module.newlyCreated)
      const addedClient = clientList?.filter((client) => client.newlyCreated)
      setDataBeforeDelete({
        addedAssignedModule,
        addedAssignedSubModule,
        addedClient,
      })
    }
  }

  //This use effect will react on create user action
  useEffect(() => {
    if (userCreatedStatus && userCreatedStatus?.[0]?.docId) {
      handleNavigateBack()
      toast.success(t('user_create_message'))
    }
  }, [userCreatedStatus])

  //This use effect will react on update user action
  useEffect(() => {
    if (userUpdateStatus && userUpdateStatus.docId) {
      handleNavigateBack()
      toast.success(t('user_update_message'))
    }
  }, [userUpdateStatus])

  useEffect(() => {
    if (userId && !searchedUserData?.docId) {
      getUserById({ id: userId })
    }
    const handleRefresh = (event: any) => {
      event.preventDefault()
      event.returnValue = ''
    }
    window.addEventListener('beforeunload', handleRefresh)
    return () => {
      window.removeEventListener('beforeunload', handleRefresh)
    }
  }, [])

  //This use effect will create assigned module and sub module object for api call
  useEffect(() => {
    handleMakeFinalData()
  }, [savedUserDetails?.assignedModule, savedUserDetails?.assignedSubModule])

  //This is for api call after delete module and client for user
  useEffect(() => {
    if (
      userId &&
      (typeof deletedClient === 'boolean' ||
        typeof deletedModule === 'boolean' ||
        typeof deletedSubModule === 'boolean') &&
      (deletedClient || deletedModule || deletedSubModule)
    ) {
      resetDeleteClient()
      resetDeleteModule()
      resetDeleteSubModule()
      getUserById({ id: userId })
    }
  }, [deletedClient, deletedModule, deletedSubModule])

  useEffect(() => {
    if (resendPassword) {
      toast.success(t('reset_password_message'))
      resetResendPasswordReset()
    }
  }, [resendPassword,resetResendPasswordReset])

  //Function that handles add client modal open
  const handleOpenModal = () => {
    setOpenModal(true)
  }

  //Function that handles add client modal close
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  //This use effect will handle user search and setting all data related to user
  useEffect(() => {
    if (searchedUserData.clientDTO && userId) {
      const assignedModule: ModuleDTO[] = []
      const assignedSubModule: SubModuleDTO[] = []
      searchedUserData?.clientDTO?.forEach((client: ClientDTO) => {
        if (client.status) {
          client.modules.forEach((module: ModuleDTO) => {
            const moduleWithClient = { ...module, client }
            assignedModule.push(moduleWithClient)
            assignedSubModule.push(
              ...module.subModuleDTOS?.map((submodule: SubModuleDTO) => ({ ...submodule, client })),
            )
          })
        }
      })
      // if (assignedModule.length) {
      //   assignedModule.splice(0, assignedModule?.length)
      // } else if (assignedSubModule.length) {
      //   assignedSubModule.splice(0, assignedSubModule?.length)
      // }
      const assignedClient: ClientDTO[] = searchedUserData?.clientDTO.filter(
        (client: ClientDTO) => client.status,
      )

      if (lastAction === '') {
        saveUserDetails({
          ...searchedUserData,
          ...searchedUserData?.userDTO,
          clientList: searchedUserData?.clientDTO.filter((client: ClientDTO) => client.status),
          assignedModule,
          assignedSubModule,
        })
      } else if (
        lastAction === 'module_delete' ||
        lastAction === 'client_delete' ||
        lastAction === 'sub_module_delete'
      ) {
        saveUserDetails({
          ...(searchedUserData ?? {}),
          ...(searchedUserData?.userDTO ?? {}),
          clientList: [...(assignedClient || []), ...(dataBeforeDelete?.addedClient || [])],
          assignedModule: [
            ...(assignedModule || []),
            ...(dataBeforeDelete?.addedAssignedModule || []),
          ],
          assignedSubModule: [
            ...(assignedSubModule || []),
            ...(dataBeforeDelete?.addedAssignedSubModule || []),
          ],
        })
      }
    }
  }, [searchedUserData])

  //This function will manupulate data for assigned module and submodule for api ready
  const handleMakeFinalData = () => {
    const groupedModuleData = savedUserDetails?.assignedModule?.reduce(
      (acc: any, obj: ModuleDTO) => {
        const existingObj = acc.find(
          (item: { clientCode: string }) => item?.clientCode === obj?.clientInfo?.code,
        )
        if (existingObj && (userId ? obj.newlyCreated : true)) {
          existingObj.moduleId.push(obj?.code)
        } else if (userId ? obj.newlyCreated : true) {
          acc.push({ clientCode: obj?.clientInfo?.code, moduleId: [obj?.code] })
        }
        return acc
      },
      [],
    )
    setFinalAssignModuleData(groupedModuleData)
    const groupedSubModuleData = savedUserDetails?.assignedSubModule?.reduce(
      (acc: any, obj: SubModuleDTO) => {
        const existingObj = acc.find(
          (item: { clientCode: string; moduleCode: string }) =>
            item.clientCode === obj?.parentModule?.clientInfo?.code &&
            item.moduleCode === obj?.parentModule?.code,
        )
        if (existingObj && (userId ? obj.newlyCreated : true)) {
          existingObj.subModuleId.push(obj?.code)
        } else if (userId ? obj.newlyCreated : true) {
          acc.push({
            clientCode: obj?.parentModule?.clientInfo?.code,
            moduleId: obj?.parentModule?.code,
            subModuleId: [obj?.code],
          })
        }
        return acc
      },
      [],
    )
    setFinalAssignSubModuleData(groupedSubModuleData)
  }

  //This function is to check if user assigned module for all clients
  const CheckAllClient = () => {
    const moduleCliets =
      (assignedModule && assignedModule.map((modules) => modules?.clientInfo?.code)) || []
    const subModuleCliets =
      (assignedSubModule &&
        assignedSubModule.map((modules) => modules?.parentModule?.clientInfo?.code)) ||
      []
    const finalClient = [...moduleCliets, ...subModuleCliets]
    const allItemsPresent = clientList.every((item) =>
      item.newlyCreated ? finalClient.includes(item?.code) : true,
    )
    return allItemsPresent
  }

  //This functions called while click on add module button and it navigates to assign module component
  const handleAssignModuleClick = () => {
    if (clientList?.length > 0) {
      setLastAction('add_module')
      saveUserDetails({
        ...savedUserDetails,
        firstName,
        designation,
        email,
        clientList,
        assignedModule,
        assignedSubModule,
        lastAction,
      })
      navigate('assignmodule')
    } else {
      toast.error(t('add_client_error'))
    }
  }
  //This will clear alld data and navigate back to user page
  const handleNavigateBack = () => {
    navigate('/users')
    resetUserDetails()
    resetCreateUser()
    resetUpdateUser()
    resetSearchedUser()
  }

  //This function will first validate and then will call create user
  const handleCheckValidation = () => {
    setSubmitClicked(true)
    if (firstName === '') toast.error(t('enter_name_message'))
    else if (designation === '') toast.error(t('enter_designation_message'))
    else if (email === '') toast.error(t('enter_email_message'))
    else if (email !== '' && !isValidEmail(email)) toast.error(t('enter_valid_email'))
    else if (clientList.length <= 0) toast.error(t('select_client_error'))
    else if (!CheckAllClient()) toast.error(t('select_module_for_client_error'))
    else handleCreateUser()
  }

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  //Function that handles create new user
  const handleCreateUser = () => {
    if (userId) {
      const updatedData: UserDTOUpdateType = {
        userDTO: [
          {
            ...savedUserDetails.userDTO,
            email,
            firstName: firstName,
            userName: email,
            designation,
          },
        ],
        userId: savedUserDetails.userDTO.id,
      }
      if (finalAssignModuleData?.length && finalAssignModuleData.length > 0) {
        updatedData['assignModules'] = finalAssignModuleData
      }
      if (finalAssignSubModuleData?.length && finalAssignSubModuleData.length > 0) {
        updatedData['assignSubModule'] = finalAssignSubModuleData
      }
      updateUserDetails(updatedData)
    } else {
      const myUserData: UserDTOCreateType = {
        userDTO: [
          {
            appUserRoles: [
              {
                roleId: 3,
              },
            ],
            designation: designation,
            email: email,
            firstName: firstName,
            userName: email,
            clientId: userData.clientId,
            module:"ARC",
          },
        ],
      }
      if (finalAssignModuleData?.length && finalAssignModuleData.length > 0) {
        myUserData['assignModules'] = finalAssignModuleData
      }
      if (finalAssignSubModuleData?.length && finalAssignSubModuleData.length > 0) {
        myUserData['assignSubModule'] = finalAssignSubModuleData
      }
      createNewUser(myUserData)
    }
  }

  //This function will update client list that respect to add client from pop up
  const handleAddClient = (clientList: ClientDTO[]) => {
    setLastAction('add_client')
    setClientList([...clientList])
    handleCloseModal()
  }

  //This function will remove client form table delete button click
  const removeClientList = (client: ClientDTO) => {
    setLastAction('client_delete')
    if (userId && !client.newlyCreated) {
      deleteUserClient({ clientCode: client.code, userId: savedUserDetails.id })
    } else {
      const filteredObjects = clientList.filter(
        (obj: { name: string }) => obj?.name !== client?.name,
      )
      setClientList([...filteredObjects])
      const filteredAssignedModule = assignedModule.filter(
        (obj: ModuleDTO) => obj.clientInfo && obj.clientInfo.docId !== client.docId,
      )
      setAssignedModule([...filteredAssignedModule])
      const filteredAssignedSubModule = assignedSubModule.filter(
        (obj: SubModuleDTO) => obj?.parentModule?.clientInfo?.docId !== client.docId,
      )
      setAssignedSubModule([...filteredAssignedSubModule])
      saveUserDetails({
        ...savedUserDetails,
        firstName,
        designation,
        email,
        clientList: [...filteredObjects],
        assignedModule: [...filteredAssignedModule],
        assignedSubModule: [...filteredAssignedSubModule],
      })
    }
  }

  const deleteModuleCard = (cardData: ModuleDTO) => {
    setLastAction('module_delete')
    if (savedUserDetails.assignedModule.length > 0 && saveUserDetails && cardData?.newlyCreated) {
      const filteredAssignedModule = savedUserDetails.assignedModule.filter(
        (obj: { docId: string }) => obj.docId !== cardData.docId,
      )
      ;(saveUserDetails as (data: SavedUserDetailsType) => void)({
        ...savedUserDetails,
        assignedModule: filteredAssignedModule,
      })
      saveUserDetails({
        ...savedUserDetails,
        firstName,
        designation,
        email,
        assignedModule: [...filteredAssignedModule],
      })
    } else if (userId) {
      deleteUserModule({
        clientId: cardData?.client?.code ?? '',
        moduleId: cardData.code,
        userId: savedUserDetails.id,
      })
    }
  }

  const deleteSubModuleCard = (cardData: SubModuleDTO) => {
    setLastAction('sub_module_delete')
    if (
      savedUserDetails?.assignedSubModule &&
      savedUserDetails.assignedSubModule.length > 0 &&
      savedUserDetails &&
      saveUserDetails
    ) {
      const filteredAssignedSubModule = savedUserDetails.assignedSubModule.filter(
        (obj: { docId: string }) => obj.docId !== cardData.docId,
      )
      ;(saveUserDetails as (data: SavedUserDetailsType) => void)({
        ...savedUserDetails,
        assignedSubModule: filteredAssignedSubModule,
      })
      saveUserDetails({
        ...savedUserDetails,
        firstName,
        designation,
        email,
        assignedSubModule: [...filteredAssignedSubModule],
      })
    }
  }

  const handleResetPass = () => {
    resendPasswordReset({ email: email })
  }

  return (
    <MainContainer>
      <StyledBreadCrumb handleDone={handleCheckValidation} handleBack={handleNavigateBack} />
      <AddUserContainer>
        <AddUserInputContainer ml={'20px'}>
          <AddUserSmallInput>
            <FullWidthStack justifyContent={'space-between'}>
              <ColumnStack width={'45%'}>
                <FullWidthStack>
                  <StyledLable>{t('name')}</StyledLable>
                  <MandatoryMark>*</MandatoryMark>
                </FullWidthStack>
                <StyledTextField
                  id='outlined-size-small'
                  size='small'
                  value={firstName}
                  onChange={(e) => setName(e.target.value?.trimStart())}
                  placeholder={`${t('enter_name')}`}
                  error={submitClicked && firstName === ''}
                />
              </ColumnStack>
              <ColumnStack width={'45%'}>
                <FullWidthStack>
                  <StyledLable>{t('designation')}</StyledLable>
                  <MandatoryMark>*</MandatoryMark>
                </FullWidthStack>
                <StyledTextField
                  id='outlined-size-small'
                  size='small'
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value?.trimStart())}
                  placeholder={`${t('enter_designation')}`}
                  error={submitClicked && designation === ''}
                />
              </ColumnStack>
            </FullWidthStack>
            <FullWidthStack justifyContent={'space-between'}>
              <ColumnStack width={'45%'}>
                <FullWidthStack>
                  <StyledLable>{t('email_id')}</StyledLable>
                  <MandatoryMark>*</MandatoryMark>
                </FullWidthStack>
                <StyledTextField
                  id='outlined-size-small'
                  size='small'
                  value={email}
                  onChange={(e) => setEmail(e.target.value?.trimStart())}
                  placeholder={`${t('enter_email')}`}
                  error={submitClicked && email === ''}
                />
              </ColumnStack>
              <ColumnStack width={'45%'}>
                {' '}
                {userId && (
                  <StyledContainedButton onClick={handleResetPass}>
                    {t('reset_pass_link')}
                  </StyledContainedButton>
                )}
              </ColumnStack>
            </FullWidthStack>
          </AddUserSmallInput>
          <AddUserSmallInput>
            <FullWidthStack>
              <Typography>{t('assigned_module')}</Typography>
            </FullWidthStack>
            <AllModuleContainer height={'100%'} gap={'1.5%'}>
              {assignedModule?.length > 0 &&
                assignedModule?.map((cardData, index: number) => (
                  <SubModuleCards
                    key={cardData?.name}
                    cardData={cardData}
                    includeButton={true}
                    isActive={true}
                    crossIcon={true}
                    backgroundimg={cardData?.logoImagePath}
                    onClickCrossButton={() => {
                      deleteModuleCard(cardData)
                    }}
                  />
                ))}
              <AddSubModuleCard onClick={handleAssignModuleClick} marginTop={'6px'}>
                <AddIcon />
                <Typography>{t('add_new')}</Typography>
              </AddSubModuleCard>
            </AllModuleContainer>
          </AddUserSmallInput>
        </AddUserInputContainer>
        <AddUserInputContainer sx={AddUserTableContainer}>
          <FullWidthStack justifyContent={'space-between'}>
            <Stack>
              <Typography>{t('client_details')}</Typography>
              <MandatoryMark>*</MandatoryMark>
            </Stack>
            <StyledButton
              variant='contained'
              bgcolor={'#A90076'}
              startIcon={<AddIcon />}
              onClick={handleOpenModal}
            >
              {t('add_client')}
            </StyledButton>
          </FullWidthStack>
          <FullWidthStack mt={'10px'}>
            {clientList?.length > 0 ? (
              <StyledTable
                tableData={clientList?.filter((client: ClientDTO) => client?.status)}
                tableHead={['name', 'code']}
                actions={['delete']}
                handleDelete={(client: ClientDTO) => removeClientList(client)}
              />
            ) : (
              <FullWidthStack sx={AddClientDesc}>
                <Typography variant='subtitle1'>{t('add_client_message')}</Typography>
              </FullWidthStack>
            )}
          </FullWidthStack>
        </AddUserInputContainer>
      </AddUserContainer>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <AddClientModal
          handleClose={handleCloseModal}
          handleAdd={handleAddClient}
          selectedClients={clientList}
        />
      </Modal>
    </MainContainer>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    savedUserDetails: userManagementEntity.getUsers(state).saveUserDetails,
    userCreatedStatus: userManagementEntity.getUsers(state).createUser,
    userUpdateStatus: userManagementEntity.getUsers(state).updateUser,
    userData: dashboardEntity.getDashboard(state).getUserData,
    searchedUserData: userManagementEntity.getUsers(state).getUserById,
    deletedClient: userManagementUI.getAllUsers(state).deleteUserClient,
    deletedModule: userManagementUI.getAllUsers(state).deleteUserModule,
    deletedSubModule: userManagementUI.getAllUsers(state).deleteUserModule,
    resendPassword: userManagementUI.getAllUsers(state).resendPasswordReset,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    saveUserDetails: (data: SavedUserDetailsType) => dispatch(saveUserDetails.request(data)),
    updateUserDetails: (data: UserDTOUpdateType) => dispatch(updateUser.request(data)),
    createNewUser: (data: UserDTOCreateType) => dispatch(addUser.request(data)),
    resetUserDetails: () => dispatch(saveUserDetails.reset()),
    resetCreateUser: () => dispatch(addUser.reset()),
    resetUpdateUser: () => dispatch(updateUser.reset()),
    getUserById: (data: GetUserById) => dispatch(getUserById.request(data)),
    deleteUserClient: (data: UserClientDeleteType) => dispatch(deleteUserClient.request(data)),
    resetDeleteClient: () => dispatch(deleteUserClient.reset()),
    deleteUserModule: (data: UserModuleDeleteType) => dispatch(deleteUserModule.request(data)),
    resetDeleteModule: () => dispatch(deleteUserModule.reset()),
    deleteUserSubModule: (data: UserSubModuleDeleteType) =>
      dispatch(deleteUserSubModule.request(data)),
    resetDeleteSubModule: () => dispatch(deleteUserSubModule.reset()),
    resetSearchedUser: () => dispatch(getUserById.reset()),
    resendPasswordReset: (data: object) => dispatch(resendPasswordReset.request(data)),
    resetResendPasswordReset: () => dispatch(resendPasswordReset.reset()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddNewUser)
