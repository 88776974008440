import { CircularProgress, Dialog } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Dispatch } from 'redux'
import { getUserLogout, renewAccessToken } from '../actions'
import { FullSizeStack, loaderProps } from '../components/Common/CommonStyles'
import TopBar from '../components/TopBar/TopBar'
import { GetAccessToken } from '../components/Types'
import { loaderStateUI, refreshAccessTokenEntity, userAuthenticationEntity } from '../reducers'
import { isLoggedIn } from './Auth'

export type ProtectedRouteProps = {
  component: JSX.Element
  getAccessToken: (data: GetAccessToken) => void
  loginUserData: any
  accessTokenStatus: any
  resetAccesTokenStatus: () => void
  logout: () => void
  loaderState: boolean | undefined
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  getAccessToken,
  loginUserData,
  accessTokenStatus,
  resetAccesTokenStatus,
  logout,
  loaderState,
}: ProtectedRouteProps) => {
  const authenticated = isLoggedIn()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const eventListenerRegisteredRef = useRef(false)
  const tokenCount = { count: 0 }

  useEffect(() => {
    if (!eventListenerRegisteredRef.current) {
      document.addEventListener('Access_Token_Event', getNewAccessToken)
      eventListenerRegisteredRef.current = true
    }
    return () => {
      document.removeEventListener('Access_Token_Event', () => {})
    }
  }, [])

  useEffect(() => {
    if (accessTokenStatus.access_token && accessTokenStatus.refresh_token) {
      tokenCount.count = 0
    }
  }, [accessTokenStatus])

  const getNewAccessToken = () => {
    if (tokenCount.count === 0) {
      tokenCount.count++
      let refresh = JSON.stringify(localStorage.getItem('refresh_token'))
      let userName = localStorage.getItem('username')
      let secretKey = localStorage.getItem('secretKey')
      getAccessToken({
        userData: {
          grant_type: 'refresh_token',
          refresh_token: refresh.replace(/"/g, ''),
          navigateTologin: backToLogin,
        },
        clientKeys: {
          oauthClientId: userName ? userName : '',
          clientSecret: secretKey ? secretKey : '',
        },
      })
    }
  }

  //This function will let the user out to login page if access token api fails 3 times
  const backToLogin = (route: string) => {
    logout()
    localStorage.clear()
    toast.error(t('session_expired'))
    navigate('/')
  }

  //This Block of code is used to get new refresh token
  useEffect(() => {
    let tokenExpireTime = localStorage.getItem('token_expire_time')
    tokenExpireTime = tokenExpireTime && JSON.parse(tokenExpireTime)
    let timeStamp = Date.now()
    let differenceTime = (tokenExpireTime ? timeStamp - parseInt(tokenExpireTime[0]) : 0) / 1000
    let remainingTime = tokenExpireTime ? parseInt(tokenExpireTime[1]) - differenceTime : -1
    if (remainingTime < 0) {
      getNewAccessToken()
    } else {
      setTimeout(() => {
        getNewAccessToken()
      }, remainingTime * 1000)
    }
  }, [])

  if (authenticated) {
    return (
      <FullSizeStack bgcolor={'#F8F9FF'} direction={'column'}>
        <TopBar />
        {component}
        {loaderState && (
          <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
        )}
      </FullSizeStack>
    )
  } else {
    toast.error(t('unauthorised_user'))
    return <Navigate to='/' />
  }
}

ProtectedRoute.propTypes = {
  getAccessToken: PropTypes.func.isRequired,
}

const mapStateToProps = (state: any) => {
  return {
    loginUserData: userAuthenticationEntity.getUserAuthentication(state).getUserAuthentication,
    accessTokenStatus: refreshAccessTokenEntity.getRefreshAccessToken(state).isRefreshTokenDone,
    loaderState: loaderStateUI.getLoaderState(state).isLoaderTrue,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAccessToken: (data: GetAccessToken) => dispatch(renewAccessToken.request(data)),
    resetAccesTokenStatus: () => dispatch(renewAccessToken.reset()),
    logout: () => dispatch(getUserLogout.request()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
