import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import {
  ASSIGN_MODULE,
  ASSIGN_SUB_MODULE,
  CREATE_CLIENTS,
  DELETE_CLIENT,
  EDIT_CLIENT,
  GET_CLIENT_FEATURES,
  GET_CLIENT_MODULES,
  GET_SUB_MODULE,
  UPDATE_CLIENT,
} from '../actions/actiontypes'
import * as ApiService from '../services'
import {
  CreateClientType,
  KeyStringValueStringSaga,
  assignModuleType,
  assignSubModuleType,
  editDeleteClientType,
  getAllClientType,
  getClientModulesType,
  getSubModuleType,
} from './Types'
import { sendPayload, sendPayloadFailure } from './helper'
const { REQUEST, GET_ALL_CLIENTS } = actionTypes

//write sagas function

function* handleGetAllClients(data: getAllClientType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllClients,
      data.data,
    )
    yield sendPayload(apiResponse, GET_ALL_CLIENTS)
  } catch (e) {
    yield sendPayloadFailure(e, GET_ALL_CLIENTS)
  }
}

function* handleCreateClients(data: CreateClientType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createClient,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_CLIENTS)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_CLIENTS)
  }
}

function* handleEditClient(data: editDeleteClientType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.editClient,
      data.data,
    )
    yield sendPayload(apiResponse, EDIT_CLIENT)
  } catch (e) {
    yield sendPayloadFailure(e, EDIT_CLIENT)
  }
}

function* handleDeleteClient(data: editDeleteClientType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteClient,
      data.data,
    )
    yield sendPayload(apiResponse, DELETE_CLIENT)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_CLIENT)
  }
}

function* handleAssignModule(data: assignModuleType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.assignModule,
      data.data,
    )
    yield sendPayload(apiResponse, ASSIGN_MODULE)
  } catch (e) {
    yield sendPayloadFailure(e, ASSIGN_MODULE)
  }
}

function* handleAssignSubModule(data: assignSubModuleType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.assignSubModule,
      data.data,
    )
    yield sendPayload(apiResponse, ASSIGN_SUB_MODULE)
  } catch (e) {
    yield sendPayloadFailure(e, ASSIGN_SUB_MODULE)
  }
}

function* handleUpdateClient(data: CreateClientType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateClient,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_CLIENT)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_CLIENT)
  }
}

function* handleGetClientModules(data: getClientModulesType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getClientModules,
      data.data,
    )
    yield sendPayload(apiResponse, GET_CLIENT_MODULES)
  } catch (e) {
    yield sendPayloadFailure(e, GET_CLIENT_MODULES)
  }
}

function* handleGetSubModules(data: getSubModuleType) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getSubModules,
      data.data,
    )
    yield sendPayload(apiResponse, GET_SUB_MODULE)
  } catch (e) {
    yield sendPayloadFailure(e, GET_SUB_MODULE)
  }
}

function* handleGetClientFeatures(data: KeyStringValueStringSaga) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getClientFeatures,
      data.data,
    )
    yield sendPayload(apiResponse, GET_CLIENT_FEATURES)
  } catch (e) {
    yield sendPayloadFailure(e, GET_CLIENT_FEATURES)
  }
}

export const sagas = {
  //watcher come here
  watchGetALlCLients: takeLatest(GET_ALL_CLIENTS[REQUEST], handleGetAllClients),
  watchCreateClient: takeLatest(CREATE_CLIENTS[REQUEST], handleCreateClients),
  watchEditClient: takeLatest(EDIT_CLIENT[REQUEST], handleEditClient),
  watchDeleteClient: takeLatest(DELETE_CLIENT[REQUEST], handleDeleteClient),
  watchAssignModule: takeLatest(ASSIGN_MODULE[REQUEST], handleAssignModule),
  watchAssignSubModule: takeLatest(ASSIGN_SUB_MODULE[REQUEST], handleAssignSubModule),
  watchUpdateClient: takeLatest(UPDATE_CLIENT[REQUEST], handleUpdateClient),
  watchGetClientModules: takeLatest(GET_CLIENT_MODULES[REQUEST], handleGetClientModules),
  watchGetSubModule: takeLatest(GET_SUB_MODULE[REQUEST], handleGetSubModules),
  watchGetClientFeatures: takeLatest(GET_CLIENT_FEATURES[REQUEST], handleGetClientFeatures),
}
