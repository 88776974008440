import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import {
  CREATE_MODULE,
  DELETE_MODULE,
  DELETE_SUB_MODULE,
  GET_ALL_MODULES,
  GET_MODULE_BY_ID,
  UPDATE_MODULE,
  UPDATE_SUB_MODULE,
} from '../actions/actiontypes'
import * as ApiService from '../services'
import {
  DeleteModuleTypesForSage,
  ModuleCreateSage,
  ModuleUpdateForSage,
  SubModuleCardDataForSage,
  SubModuleDeleteForSage,
} from './Types'
import { sendPayload, sendPayloadFailure } from './helper'
const { REQUEST, REFRESH_ACCESS_TOKEN } = actionTypes

//write sagas function

function* handleGetAllModule() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getAllModule)
    yield sendPayload(apiResponse, GET_ALL_MODULES)
  } catch (e) {
    yield sendPayloadFailure(e, GET_ALL_MODULES)
  }
}

function* handleCreateModule(data: ModuleCreateSage) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createModuleEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_MODULE)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_MODULE)
  }
}

function* handleUpdateModule(data: ModuleUpdateForSage) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateModuleEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_MODULE)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_MODULE)
  }
}

function* handleDeleteModule(data: DeleteModuleTypesForSage) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteModuleEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, DELETE_MODULE)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_MODULE)
  }
}

function* handleGetModuleById(data: DeleteModuleTypesForSage) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getModuleByIdEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, GET_MODULE_BY_ID)
  } catch (e) {
    yield sendPayloadFailure(e, GET_MODULE_BY_ID)
  }
}

function* handleDeleteSubModule(data: SubModuleDeleteForSage) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteSubModuleEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, DELETE_SUB_MODULE)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_SUB_MODULE)
  }
}

function* handleUpdateSubModule(data: SubModuleCardDataForSage) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateSubModuleEndPoint,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_SUB_MODULE)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_SUB_MODULE)
  }
}

export const sagas = {
  //watcher come here
  watchGetAllModule: takeLatest(actionTypes.GET_ALL_MODULES[REQUEST], handleGetAllModule),
  createModule: takeLatest(actionTypes.CREATE_MODULE[REQUEST], handleCreateModule),
  deleteModule: takeLatest(actionTypes.DELETE_MODULE[REQUEST], handleDeleteModule),
  getModuleById: takeLatest(actionTypes.GET_MODULE_BY_ID[REQUEST], handleGetModuleById),
  updateModule: takeLatest(actionTypes.UPDATE_MODULE[REQUEST], handleUpdateModule),
  deleteSubModule: takeLatest(actionTypes.DELETE_SUB_MODULE[REQUEST], handleDeleteSubModule),
  updateSubModule: takeLatest(actionTypes.UPDATE_SUB_MODULE[REQUEST], handleUpdateSubModule),
}
