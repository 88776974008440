import AddIcon from '@mui/icons-material/Add'
import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Dispatch } from 'redux'
import {
  createModule,
  createSubModule,
  deleteSubModule,
  getModuleById,
  saveModuleName,
  saveSubModuleDetails,
  updateModule,
} from '../../actions'
import { RootState } from '../../configureStore'
import { moduleManagementEntity, moduleManagementUI } from '../../reducers'
import {
  AddSubModuleCard,
  ColumnStack,
  FullWidthStack,
  MandatoryMark,
  StyledLable,
  StyledTextField,
} from '../Common/CommonStyles'
import DragDrop from '../Common/DragDrop'
import StyledBreadCrumb from '../Common/StyledBreadCrumb'
import {
  AllModuleContainer,
  MainContainer,
  ModuleNameContainer,
  NewModuleHeader,
  SubModuleContainer,
} from './ModuleManagementComponents'
import { ModuleById, ModuleCreate, ModuleUpdate, NewModuleSave } from './ModuleManagementTypes'
import SubModuleCard from './SubModuleCard'

interface AddModuleProps {
  createModule: (data: ModuleCreate) => void
  saveModuleName: (data: ModuleUpdate | NewModuleSave) => void
  updateModule: (data: ModuleUpdate) => void
  getModuleById: (data: ModuleById) => void
  createSubModule: (data: NewModuleSave[]) => void
  createdModule: ModuleUpdate
  subModules: ModuleUpdate[]
  savedModuleName: ModuleUpdate | NewModuleSave
  searchedModule: ModuleUpdate
  updatedModule: ModuleUpdate
  deletedSubModuleSuccess: boolean
  resetModule: () => void
  resetSearchedModule: () => void
  resetUpdateModule: () => void
  resetSubModule: () => void
  resetModuleName: () => void
  resetdeleteSubModule: () => void
  resetSavedSubModule: () => void
}

const AddNewModule = (props: AddModuleProps) => {
  const {
    createModule,
    createdModule,
    resetModule,
    subModules,
    savedModuleName,
    saveModuleName,
    getModuleById,
    searchedModule,
    resetSearchedModule,
    updateModule,
    updatedModule,
    resetUpdateModule,
    createSubModule,
    resetSubModule,
    resetModuleName,
    deletedSubModuleSuccess,
    resetdeleteSubModule,
    resetSavedSubModule,
  } = props
  const { t } = useTranslation()
  const [moduleName, setModuleName] = useState(
    savedModuleName && savedModuleName.name ? savedModuleName.name : '',
  )
  const [moduleURL, setModuleURL] = useState(
    savedModuleName && savedModuleName.link ? savedModuleName.link : '',
  )
  const [moduleCoverImage, setModuleCoverImage] = useState(
    savedModuleName && savedModuleName.logoImagePath ? savedModuleName.logoImagePath : '',
  )
  const [searchedModuleData, setSearchedModuleData] = useState<{
    docId?: string
    link?: string
    name?: string
    subModuleDTOS?: NewModuleSave[]
    code?: string
  }>({})
  const [submitClick, setSubmitClick] = useState(false)
  const navigate = useNavigate()
  const editedModuleId = savedModuleName?.code ? savedModuleName.code : null

  //Getting searched id from local storage
  const params = localStorage.getItem('queryParams')
  const storedQueryParams = params?.replace('=', '')?.replace('?', '')

  useEffect(() => {
    const warnRefresh = (event: BeforeUnloadEvent) => {
      if (
        storedQueryParams &&
        (searchedModuleData.name !== moduleName ||
          searchedModuleData.link !== moduleURL ||
          searchedModuleData.link !== moduleCoverImage)
      ) {
        event.preventDefault()
        event.returnValue = ''
        return 'Do you want to refresh?'
      } else if (
        moduleName !== '' ||
        moduleURL !== '' ||
        moduleCoverImage !== '' ||
        subModules?.length > 0
      ) {
        event.preventDefault()
        event.stopPropagation()
        event.returnValue = ''
        return 'Do you want to refresh?'
      }
    }
    //Adding event listner to warn user before refresh
    window.addEventListener('beforeunload', warnRefresh)
    return () => {
      window.removeEventListener('beforeunload', warnRefresh)
    }
  }, [searchedModuleData, moduleName, moduleURL, moduleCoverImage, subModules])

  //This useEffect will retrive data of module that to edit
  useEffect(() => {
    if (storedQueryParams) {
      getModuleById({ id: storedQueryParams })
    }
  }, [storedQueryParams])

  //This useEffect will naviate user to module page after successfully add module
  useEffect(() => {
    if (createdModule && createdModule.id) {
      toast.success(t('module_add_message'))
      resetModule()
      resetSavedSubModule()
      handleNavigateBack()
      navigate('/module')
    }
  }, [createdModule])

  useEffect(() => {
    if (deletedSubModuleSuccess === true) {
      resetSubModule()
      if (editedModuleId) {
        getModuleById({ id: editedModuleId })
      }
      resetdeleteSubModule()
    }
  }, [deletedSubModuleSuccess])

  //This useEffect will naviate user to module page after successfully module updated
  useEffect(() => {
    if (updatedModule && updatedModule.id) {
      toast.success(t('module_update_message'))
      resetSavedSubModule()
      handleNavigateBack()
      navigate('/module')
    }
  }, [updatedModule])

  //This useEffect will set module name when we edit a module
  useEffect(() => {
    if (storedQueryParams) {
      setModuleName(searchedModule.name)
      setModuleURL(searchedModule.link)
      setModuleCoverImage(searchedModule.logoImagePath)
      setSearchedModuleData(searchedModule)
      createSubModule(
        searchedModule.subModuleDTOS &&
          searchedModule.subModuleDTOS.length > 0 &&
          searchedModule.subModuleDTOS.length > subModules.length
          ? searchedModule.subModuleDTOS
          : subModules,
      )
    }
  }, [searchedModule])

  //Handle file operation related to drag and drop and selecting file for client cover image
  const handleDrop = (imageUrl: string): void => {
    setModuleCoverImage(imageUrl)
  }
  //This function will be called on add submodule card click and will let you navigate to add submodule page
  const handleAddModuleClick = () => {
    const savedModule = savedModuleName
      ? savedModuleName
      : { name: '', link: '', logoImagePath: '' }
    savedModule.name = moduleName
    savedModule.link = moduleURL
    savedModule.logoImagePath = moduleCoverImage
    saveModuleName(savedModule)
    navigate('addsubmodule')
  }

  //To set moduleName
  const handleModuleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModuleName(e.target.value?.trimStart())
  }
  //To set module extrenal URL
  const handleModuleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModuleURL(e.target.value?.trimStart())
  }
  //This function will check validation for all input fields
  const checkModuleValidation = () => {
    setSubmitClick(true)
    if (moduleName === '') toast.error(t('module_required'))
    else if (moduleURL === '') toast.error(t('module_url_required'))
    else if (moduleCoverImage === '') toast.error(t('Module_image_required'))
    else handleCreateModule()
  }
  //Function that is responsible for create and update module
  const handleCreateModule = () => {
    if (!storedQueryParams) {
      createModule({
        visible: true,
        activeFlag: true,
        createdBy: 'system',
        lastModifiedBy: 'system',
        name: moduleName,
        logoImagePath: moduleCoverImage,
        link: moduleURL,
        subModuleDTOS: subModules,
        isAssigned: false,
      })
    } else {
      updateModule({
        docId: searchedModuleData ? searchedModuleData.docId : '',
        id: searchedModuleData.docId ? searchedModuleData.docId : '',
        code: searchedModuleData.code ? searchedModuleData.code : '',
        visible: true,
        activeFlag: true,
        createdBy: 'system',
        lastModifiedBy: 'system',
        name: moduleName,
        link: moduleURL,
        logoImagePath: moduleCoverImage,
        subModuleDTOS: subModules,
        isAssigned: false,
      })
    }
  }
  //This function trigger when we click back from breadcrumb
  const handleNavigateBack = () => {
    resetSubModule()
    resetSearchedModule()
    resetModule()
    resetModuleName()
    resetUpdateModule()
    resetSavedSubModule()
    localStorage.removeItem('queryParams')
  }

  return (
    <MainContainer>
      <StyledBreadCrumb handleDone={checkModuleValidation} handleBack={handleNavigateBack} />
      <FullWidthStack sx={NewModuleHeader}>
        <ColumnStack sx={ModuleNameContainer}>
          <FullWidthStack>
            <StyledLable>{t('module_name')}</StyledLable>
            <MandatoryMark>*</MandatoryMark>
          </FullWidthStack>
          <StyledTextField
            id='outlined-size-small'
            size='small'
            value={moduleName}
            onChange={handleModuleNameChange}
            error={submitClick && moduleName === ''}
            placeholder={`${t('enter_application_name')}`}
          />
          <FullWidthStack>
            <StyledLable>{t('external_module_url')}</StyledLable>
            <MandatoryMark>*</MandatoryMark>
          </FullWidthStack>
          <StyledTextField
            id='outlined-size-small'
            size='small'
            value={moduleURL}
            onChange={handleModuleURLChange}
            error={submitClick && moduleURL === ''}
            placeholder={`${t('enter_application_url')}`}
          />
        </ColumnStack>
        <ColumnStack width={'30%'} height={'100%'}>
          <FullWidthStack>
            <StyledLable>{t('module_cover_image')}</StyledLable>
            <MandatoryMark>*</MandatoryMark>
            <Typography color={'gray'} fontSize={'10px'} ml={'5px'}>
              {t('logo_error')}
            </Typography>
          </FullWidthStack>
          <FullWidthStack>
            <DndProvider backend={HTML5Backend}>
              <DragDrop onDrop={handleDrop} coverImageUrl={moduleCoverImage} />
            </DndProvider>
          </FullWidthStack>
        </ColumnStack>
      </FullWidthStack>
      <FullWidthStack sx={SubModuleContainer}>
        <FullWidthStack marginTop={'10px'}>
          <StyledLable>{t('submodule')}</StyledLable>
        </FullWidthStack>
        <AllModuleContainer height={'100%'} gap={'1.5%'}>
          {subModules &&
            subModules.length > 0 &&
            subModules?.map((cardData: ModuleUpdate) => {
              if (cardData.activeFlag) {
                return (
                  <SubModuleCard
                    key={cardData.name}
                    cardData={cardData}
                    includeButton={true}
                    parentModule={savedModuleName}
                  />
                )
              }
            })}
          <AddSubModuleCard onClick={handleAddModuleClick}>
            <AddIcon />
            <Typography>{t('add_new')}</Typography>
          </AddSubModuleCard>
        </AllModuleContainer>
      </FullWidthStack>
    </MainContainer>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    createdModule: moduleManagementEntity.getModules(state).createModule,
    subModules: moduleManagementEntity.getModules(state).createSubModule,
    savedModuleName: moduleManagementEntity.getModules(state).saveModuleName,
    searchedModule: moduleManagementEntity.getModules(state).getModuleById,
    updatedModule: moduleManagementEntity.getModules(state).updateModule,
    deletedSubModuleSuccess: moduleManagementUI.getAllModule(state).deleteSubModule,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createModule: (data: ModuleCreate) => dispatch(createModule.request(data)),
    resetModule: () => dispatch(createModule.reset()),
    saveModuleName: (data: ModuleUpdate | NewModuleSave) => dispatch(saveModuleName.request(data)),
    resetModuleName: () => dispatch(saveModuleName.reset()),
    getModuleById: (data: ModuleById) => dispatch(getModuleById.request(data)),
    resetSearchedModule: () => dispatch(getModuleById.reset()),
    updateModule: (data: ModuleUpdate) => dispatch(updateModule.request(data)),
    resetUpdateModule: () => dispatch(updateModule.reset()),
    createSubModule: (data: NewModuleSave[]) => dispatch(createSubModule.request(data)),
    resetSubModule: () => dispatch(createSubModule.reset()),
    resetdeleteSubModule: () => dispatch(deleteSubModule.reset()),
    resetSavedSubModule: () => dispatch(saveSubModuleDetails.reset()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddNewModule)
