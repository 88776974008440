import DoneIcon from '@mui/icons-material/Done'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import ClientManagementImage from '../../assets/images/client-management-icon.png'
import AddModuleImage from '../../assets/images/product-details-icon-design-symbol-price-transaction-invoice-paper-document-for-ecommerce-free-vecto@2x.png'
import ModuleManagementImage from '../../assets/images/product-unit-line-icon-marketing-260nw-1371853472@2x.png'
import UserManagementImage from '../../assets/images/user_management.png'
import { Primary } from '../../utils'
import { Image } from '../Module Management/ModuleManagementComponents'
import {
  BreadCrumbContainer,
  FlexendStack,
  SmallIconButton,
  StyledBreadCrumbs,
  StyledLink,
} from './CommonStyles'
import { IStyledBreadCrumb } from './Types'

const StyledBreadCrumb = ({ handleDone, handleBack }: IStyledBreadCrumb) => {
  const location = useLocation()
  const pathnames = location?.pathname?.split('/')?.filter((path) => path)
  const searchedString = location?.search
  const saveRoute = { previousRoute: '' }
  const navigate = useNavigate()
  const { t } = useTranslation()

  //This function is responsible to set name for breadcrumb accorsing to URL
  const getBreadcrumbLabel = (pathname: string) => {
    switch (pathname) {
      case 'module':
        return `${t('module_management')}`
      case 'addmodule':
        return `${t('add_modules')}`
      case 'addsubmodule':
        return `${t('add_submodule')}`
      case 'details':
        return `${t('client_details')}`
      case 'clients':
        return `${t('client_management')}`
      case 'assignmodule':
        return `${t('assign_module')}`
      case 'users':
        return `${t('users_management')}`
      case 'adduser':
        return `${t('add_user')}`
      case 'edituser':
        return `${t('edit_user')}`
      case 'assignsubmodule':
        return `${t('assign_submodule')}`
      default:
        //Here in this line we are returning dynamic value to the breadcrumb for submodule add purpose and convering url to string
        return pathname.replaceAll('%20', ' ')
    }
  }

  //This function is responsible to set icons for breadcrumb
  const getBreadcrumbIcon = (pathname: string) => {
    switch (pathname) {
      case 'module':
        return ModuleManagementImage
      case 'clients':
        return ClientManagementImage
      case 'users':
        return UserManagementImage
      default:
        return AddModuleImage
    }
  }

  // This function will navigate you back to previous route
  const handleBackClick = () => {
    if (handleBack) {
      handleBack()
    }
    if (saveRoute.previousRoute?.concat(searchedString)?.split('?')?.[0]?.split('/')?.length === 2)
      navigate(saveRoute.previousRoute)
    else {
      navigate(saveRoute.previousRoute?.concat(searchedString))
    }
  }

  return (
    <BreadCrumbContainer>
      <Stack width={'85%'}>
        <StyledBreadCrumbs separator='›' aria-label='breadcrumb'>
          {pathnames?.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
            if (pathnames.length - 2 === index) {
              saveRoute.previousRoute = routeTo
            }
            return (
              <Stack gap={'15px'} key={routeTo}>
                <Image src={getBreadcrumbIcon(name)} alt='Icon' size={'25px'} />
                <StyledLink
                  to={routeTo.concat(searchedString)}
                  key={routeTo}
                  onClick={handleBackClick}
                >
                  <Typography variant='body2' fontWeight={500}>
                    {getBreadcrumbLabel(name)}
                  </Typography>
                </StyledLink>
              </Stack>
            )
          })}
        </StyledBreadCrumbs>
      </Stack>
      <FlexendStack width={'15%'} gap={'25px'}>
        <SmallIconButton variant='outlined' onClick={handleBackClick}>
          <KeyboardBackspaceIcon />
        </SmallIconButton>
        <SmallIconButton iconbuttoncolor={Primary} onClick={handleDone}>
          <DoneIcon />
        </SmallIconButton>
      </FlexendStack>
    </BreadCrumbContainer>
  )
}

StyledBreadCrumb.propTypes = {
  handleDone: PropTypes.func,
  handleBack: PropTypes.func,
}

export default StyledBreadCrumb
