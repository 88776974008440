import { Stack, TableContainer, Typography, Box } from '@mui/material'
import swapImg from '../../assets/images/swap_vert_black_24dp.svg'
import group from '../../assets/images/Group_1808.svg'
import { ThemeProvider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  TypeToSearch,
  StyledSearchInputBase,
  StyledSearchIcon,
  StyledSortButton,
  StyledContainedButton,
  StyledAddRoundedIcon,
  StyledHTMLImg,
} from '../Common/CommonStyles'
import {
  iconTheme,
  StyledHeaderTypography,
  StyledBoxHeader,
  StyledMainStack,
  StyledMainHeaderStack,
  Heading,
  SubHeading,
  StyledSecondStack,
  StyledBoxHeaderCellID,
  StyledBoxHeaderCellClientName,
  StyledBoxHeaderCellEmailID,
  StyledBoxHeaderCellModuleClient,
  StyledBoxHeaderCellAction,
  PaginationStack,
  TableDataBox,
  MainContainer,
  clientManagementTableStyles,
} from './ClientsStyles'
import React, { useEffect, useState } from 'react'
import CustomPagination from '../Common/StyledPagination'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { clientManagementEntity, clientManagementUI } from '../../reducers'
import { Dispatch } from 'redux'
import {
  getAllClients,
  deleteClient,
  clientInfo,
  getAssignedModules,
  createClient,
  clientDataFlag,
  editClient,
  featureIdInfo,
} from '../../actions'
import { RootState } from '../../configureStore'
import { editDeleteClientRequestType, getAllClientsType } from '../../actions/Types'
import PropTypes from 'prop-types'
import DeletePopup from '../Common/DeletePopup'
import NoData from '../Common/NoData'
import StyledTable from '../Common/StyledTable'
/* eslint-disable */

type clientManagement = {
  fetchAllClientData: (data?: getAllClientsType) => void
  deleteClientData: (data: editDeleteClientRequestType) => void
  allClientData: { content: [] }
  deleteDone: boolean
  resetDeleteDone: () => void
  resetClientInfo: () => void
  resetAssignedModule: () => void
  resetCreateClient: () => void
  resetClientDetails: () => void
  clientfetchReset: () => void
  resetFeatureIdInfo: () => void
}

interface ModuleArray {
  length: number
  index: number
}

type client = {
  id: {
    timestamp: number
  }
  docId: string
  code: string
  modules: ModuleArray
  type: string
  name: string
  email: string
  users: []
  logoImagePath: string
}

type tableData = {
  code: string
  company_name: string
  admins: []
  module_count: number
  docId: string
  logo: string
}

const ClientManagement = (props: clientManagement) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [deletePopup, setDeletePopup] = React.useState<boolean>(false)
  const {
    fetchAllClientData,
    allClientData,
    deleteClientData,
    deleteDone,
    resetDeleteDone,
    resetClientInfo,
    resetAssignedModule,
    resetClientDetails,
    clientfetchReset,
    resetFeatureIdInfo,
  } = props
  const [tableData, setTableData] = useState<tableData[]>([])
  const [searchedData, setSearchedData] = useState<tableData[]>([])
  const [searchedValue, setSearchedValue] = useState('')
  const [deleteDocId, setDeleteDocId] = useState('')
  useEffect(() => {
    fetchAllClientData({
      page: 0,
      size: 500,
    })
    resetClientInfo()
    resetAssignedModule()
    resetClientDetails()
    clientfetchReset()
    localStorage.removeItem('clientId')
    resetFeatureIdInfo()
  }, [])
  useEffect(() => {
    if (allClientData) {
      setTableData(
        allClientData?.content?.map((client: client) => {
          return {
            code: client?.code,
            company_name: client?.name,
            admins: client?.users,
            module_count: client?.modules.length,
            docId: client?.code,
            logo: client?.logoImagePath,
          }
        }),
      )
    }
  }, [allClientData])
  useEffect(() => {
    if (deleteDone) {
      fetchAllClientData({
        page: 0,
        size: 500,
      })
      resetDeleteDone()
    }
  }, [deleteDone])

  //handle Add CLient on click
  const handleAddClient = () => {
    navigate('/clients/details')
  }

  //Open delete popup
  const openDeletePopup = (data: { code: string }) => {
    setDeleteDocId(data?.code)
    setDeletePopup(true)
  }

  //Delete popup trigger actions
  const handleDeletePopup = (action: string) => {
    if (action === 'cancel') {
      setDeletePopup(false)
    } else if (action === 'delete') {
      deleteClientData({ id: deleteDocId })
      setDeletePopup(false)
    }
  }
  //handle edit the client
  const handleEdit = (data: { code: string }) => {
    localStorage.setItem('clientId', data?.code)
    navigate(`/clients/details`)
  }

  const handleSortButton = () => {
    tableData.sort((a: tableData, b: tableData) => a?.company_name.localeCompare(b?.company_name))
  }

  const handleSearchField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedValue(e.target.value)
    if (e.target.value.trim().length > 0)
      setSearchedData(
        tableData?.filter((data: tableData) => {
          return data?.company_name
            ?.trim()
            ?.toLowerCase()
            .includes(e.target.value.trim().toLowerCase())
        }),
      )
  }

  return (
    <MainContainer>
      <StyledMainStack spacing={1.5} direction={'column'}>
        <StyledMainHeaderStack direction='row' spacing={4}>
          <Stack width={'65%'}>
            <Box>
              <Typography sx={Heading}>{t('client_management')}</Typography>
              <Typography sx={SubHeading} variant='caption'>
                {t('manage_your_client_with_assigned_modules')}
              </Typography>
            </Box>
          </Stack>
        </StyledMainHeaderStack>
        <StyledSecondStack direction='row'>
          <Stack direction='row' spacing={2.5}>
            <TypeToSearch>
              <StyledSearchIcon />
              <StyledSearchInputBase
                placeholder={`${t('type_to_search')}`}
                inputProps={{ 'aria-label': 'Type to search' }}
                onChange={handleSearchField}
              />
            </TypeToSearch>
            <StyledSortButton variant='outlined' onClick={handleSortButton}>
              <StyledHTMLImg src={swapImg} alt='swapImg' />
              {t('sort_by_name')}
            </StyledSortButton>
          </Stack>
          <ThemeProvider theme={iconTheme}>
            <StyledContainedButton startIcon={<StyledAddRoundedIcon />} onClick={handleAddClient}>
              {t('add_client')}
            </StyledContainedButton>
          </ThemeProvider>
        </StyledSecondStack>
        {!Boolean(allClientData?.content?.length) && (
          <TableContainer sx={{ marginTop: '15px !important' }}>
            <StyledBoxHeader>
              <StyledBoxHeaderCellID>
                <StyledHeaderTypography ml={0.5}> {t('code')} </StyledHeaderTypography>
              </StyledBoxHeaderCellID>
              <StyledBoxHeaderCellClientName>
                <StyledHeaderTypography>{t('company_name')}</StyledHeaderTypography>
                <StyledHTMLImg src={group} alt='group' />
              </StyledBoxHeaderCellClientName>
              <StyledBoxHeaderCellEmailID>
                <StyledHeaderTypography> {t('admins')} </StyledHeaderTypography>
              </StyledBoxHeaderCellEmailID>
              <StyledBoxHeaderCellModuleClient>
                <StyledHeaderTypography>{t('module_count')}</StyledHeaderTypography>
                <StyledHTMLImg src={group} alt='group' />
              </StyledBoxHeaderCellModuleClient>
              <StyledBoxHeaderCellAction>
                <StyledHeaderTypography>{t('action')}</StyledHeaderTypography>
              </StyledBoxHeaderCellAction>
            </StyledBoxHeader>
            <Box sx={TableDataBox}>
              <NoData></NoData>
            </Box>
          </TableContainer>
        )}
        {Boolean(allClientData?.content?.length) && allClientData?.content?.length !== 0 && (
          <StyledTable
            tableData={searchedValue.trim().length > 0 ? searchedData : tableData}
            handleDelete={openDeletePopup}
            handleEdit={handleEdit}
            actions={['edit', 'delete']}
            tableHead={['code', 'company_name', 'admins', 'module_count']}
            styles={clientManagementTableStyles}
          ></StyledTable>
        )}
      </StyledMainStack>
      <DeletePopup open={deletePopup} triggerAction={handleDeletePopup}></DeletePopup>
    </MainContainer>
  )
}

ClientManagement.prototype = {
  fetchAllClientData: PropTypes.func.isRequired,
  deleteClientData: PropTypes.func.isRequired,
  allClientData: PropTypes.shape({ content: PropTypes.array }),
  deleteDone: PropTypes.bool,
  resetDeleteDone: PropTypes.func,
  resetClientInfo: PropTypes.func,
  resetAssignedModule: PropTypes.func,
  resetCreateClient: PropTypes.func,
}
const mapStateToProps = (state: RootState) => {
  return {
    allClientData: clientManagementEntity.getClientManagementData(state).getAllClients,
    allClientDataPass: clientManagementUI.getClientManagementData(state).getAllClients,
    deleteDone: clientManagementUI.getClientManagementData(state).deleteClient,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchAllClientData: (data?: getAllClientsType) => dispatch(getAllClients.request(data)),
    deleteClientData: (data: editDeleteClientRequestType) => dispatch(deleteClient.request(data)),
    resetDeleteDone: () => dispatch(deleteClient.reset()),
    resetClientInfo: () => dispatch(clientInfo.resetClientInfo()),
    resetAssignedModule: () => dispatch(getAssignedModules.resetAssignedModule()),
    resetCreateClient: () => dispatch(createClient.reset()),
    resetClientDetails: () => dispatch(editClient.reset()),
    clientfetchReset: () => dispatch(clientDataFlag.reset()),
    resetFeatureIdInfo: () => dispatch(featureIdInfo.resetFeatureIds()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClientManagement)
