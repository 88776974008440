import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RESET } from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, UPLOAD_IMAGE } = actionTypes

const entity = () => {
  const uploadImage = (state = [], action: actions) => {
    switch (action.type) {
      case UPLOAD_IMAGE[SUCCESS]:
        return action.payload
      case UPLOAD_IMAGE[REQUEST]:
        return []
      case UPLOAD_IMAGE[RESET]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    uploadImage,
  })
}

export default entity

export const getUploadImage = (state: RootState) => state.entities.uploadImage
