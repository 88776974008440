import AddIcon from '@mui/icons-material/Add'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import {
  createModule,
  createSubModule,
  deleteModule,
  deleteSubModule,
  getAllModules,
  getModuleById,
  saveModuleName,
  saveSubModuleDetails,
  updateModule,
} from '../../actions'
import { RootState } from '../../configureStore'
import { moduleManagementEntity } from '../../reducers'
import { FlexendStack } from '../Common/CommonStyles'
import NoData from '../Common/NoData'
import { StyledSearchIcon, StyledSearchInputBase } from '../Dashboard/DashboardStyles'
import { Heading, SubHeading } from '../User_Management/UserManagementStyles'
import ModuleCards from './ModuleCards'
import {
  AllModuleContainer,
  ContentBox,
  CustomTab,
  CustomTabs,
  DescriptionBox,
  MainContainer,
  StyledButton,
  TypeToSearch,
} from './ModuleManagementComponents'
import { ModuleCardTypes } from './ModuleManagementTypes'

interface ModuleManagementProps {
  getAllModules: () => void
  allModules: { content: ModuleCardTypes[] }
  deletedModule: { name?: string }
  resetDeleted: () => void
  resetSubModule: () => void
  resetSearchedModule: () => void
  resetModule: () => void
  resetModuleName: () => void
  resetUpdateModule: () => void
  resetSavedSubModule: () => void
}

const ModuleManagement = (props: ModuleManagementProps) => {
  const {
    getAllModules,
    allModules,
    deletedModule,
    resetSubModule,
    resetSearchedModule,
    resetModule,
    resetModuleName,
    resetUpdateModule,
    resetSavedSubModule,
  } = props
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const [moduleData, setmoduleData] = useState(
    allModules?.content?.filter((data: { activeFlag: boolean }) => data.activeFlag === true),
  )
  const [searchedValue, setSearchedValue] = useState('')
  const [searchedData, setSearchedData] = useState<{
    [key: number]: ModuleCardTypes[]
  }>({
    0: [],
    1: [],
  })
  const navigate = useNavigate()

  //This useEffect will get all Modules in initial load as well when we delete one module
  useEffect(() => {
    getAllModules()
  }, [deletedModule])

  //This useEffect will be called on initial load
  useEffect(() => {
    handleNavigateBack()
  }, [])

  //This use Effect will set all modules while refershing the page
  useEffect(() => {
    const data = allModules?.content?.filter(
      (data: { activeFlag: boolean }) => data.activeFlag === true,
    )
    data && setmoduleData([...data])
  }, allModules?.content)

  //This function handle change of tabs
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    let filteredData
    switch (newValue) {
      case 0:
        filteredData = allModules?.content?.filter(
          (data: { activeFlag: boolean }) => data.activeFlag === true,
        )
        if (filteredData) setmoduleData([...filteredData])
        break
      case 1:
        filteredData = allModules?.content?.filter(
          (data: { activeFlag: boolean }) => data.activeFlag === false,
        )
        if (filteredData) setmoduleData([...filteredData])
        break
    }
  }

  //This functions called while click on add module button and it navigates to add module component
  const handleAddModuleClick = () => {
    navigate('./addmodule')
  }

  //This function called while clicking on sort button and it will sort module card by name
  const handleSortModule = () => {
    let sort =
      moduleData &&
      moduleData.length > 0 &&
      moduleData?.sort((a: { name: string }, b: { name: string }) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    sort && setmoduleData([...sort])
  }

  //This function trigger when we click back from breadcrumb
  const handleNavigateBack = () => {
    resetSubModule()
    resetSearchedModule()
    resetModule()
    resetModuleName()
    resetUpdateModule()
    resetSavedSubModule()
    localStorage.removeItem('queryParams')
  }

  const handleSearchField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedValue(e.target.value)
    if (e.target.value.trim().length > 0)
      setSearchedData({
        0: allModules?.content?.filter((data: { activeFlag: boolean; name: string }) => {
          return (
            data.activeFlag === true &&
            data?.name?.trim()?.toLowerCase()?.includes(e.target.value?.trim().toLowerCase())
          )
        }),
        1: allModules?.content?.filter((data: { activeFlag: boolean; name: string }) => {
          return (
            data.activeFlag === false &&
            data?.name?.trim()?.toLowerCase()?.includes(e.target.value?.trim().toLowerCase())
          )
        }),
      })
  }

  return (
    <>
      <MainContainer>
        <DescriptionBox>
          <ContentBox>
            <Stack width={'65%'}>
              <Box>
                <Typography sx={Heading}>{t('module_management')}</Typography>
                <Typography sx={SubHeading} variant='caption'>
                  {t('module_management_caption')}
                </Typography>
              </Box>
            </Stack>
            <FlexendStack width={'35%'}>
              <TypeToSearch>
                <StyledSearchIcon />
                <StyledSearchInputBase
                  placeholder={`${t('type_to_search')}`}
                  inputProps={{ 'aria-label': 'Type to search' }}
                  onChange={handleSearchField}
                />
              </TypeToSearch>
            </FlexendStack>
          </ContentBox>
          <ContentBox>
            <Stack width={'65%'}>
              <CustomTabs onChange={handleChange} value={value} indicatorColor='secondary'>
                <CustomTab label={t('active')} />
                <CustomTab label={t('dormant')} />
                {/* <CustomTab label={t('inactive_modules', { inActiveModuleCount })} /> */}
              </CustomTabs>
            </Stack>
            <FlexendStack width={'35%'} gap={'20px'}>
              <StyledButton
                startIcon={<ImportExportIcon />}
                variant='outlined'
                onClick={handleSortModule}
              >
                {t('sort_by_name')}
              </StyledButton>
              <StyledButton
                variant='contained'
                bgcolor={'#A90076'}
                startIcon={<AddIcon />}
                onClick={handleAddModuleClick}
              >
                {t('add_modules')}
              </StyledButton>
            </FlexendStack>
          </ContentBox>
        </DescriptionBox>
        <AllModuleContainer>
          {moduleData?.length > 0 && searchedValue.trim().length === 0 ? (
            moduleData?.map((cardData: ModuleCardTypes, index: number) => (
              <ModuleCards key={index + Date.now()} cardData={cardData} />
            ))
          ) : searchedValue.trim().length === 0 ? (
            <NoData />
          ) : (
            ''
          )}
          {searchedData[value]?.length > 0 &&
            searchedValue.trim().length > 0 &&
            searchedData[value]?.map((cardData: ModuleCardTypes, index: number) => (
              <ModuleCards key={index + Date.now()} cardData={cardData} />
            ))}
        </AllModuleContainer>
      </MainContainer>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    allModules: moduleManagementEntity.getModules(state).getAllModules,
    deletedModule: moduleManagementEntity.getModules(state).deleteModule,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllModules: () => dispatch(getAllModules.request()),
    resetDeleted: () => dispatch(deleteModule.reset()),
    resetModule: () => dispatch(createModule.reset()),
    resetModuleName: () => dispatch(saveModuleName.reset()),
    resetSearchedModule: () => dispatch(getModuleById.reset()),
    resetUpdateModule: () => dispatch(updateModule.reset()),
    resetSubModule: () => dispatch(createSubModule.reset()),
    resetdeleteSubModule: () => dispatch(deleteSubModule.reset()),
    resetSavedSubModule: () => dispatch(saveSubModuleDetails.reset()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModuleManagement)
