import { Box, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { deleteModule, saveModuleName } from '../../actions'
import BackImage from '../../assets/images/Mask.jpg'
import DeletePopup from '../Common/DeletePopup'
import StyledPopper from '../Common/StyledPopper'
import { StyledCard, WhiteMoreHorizIcon } from './ModuleManagementComponents'
import { DeleteModuleTypes, ModuleCardTypes } from './ModuleManagementTypes'

interface ModuleCardsType {
  cardData: ModuleCardTypes
  deleteModule: (data: DeleteModuleTypes) => void
  saveModuleName: (data: ModuleCardTypes) => void
  key?: number
}

const ModuleCards = (props: ModuleCardsType) => {
  const { cardData, deleteModule, saveModuleName } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  //This function will open popper menu while hover on more icon
  const handleOpenMenu = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget)
  }

  //This function will close popper menu while mouse out from more icon
  const handleCloseMenu = () => {
    setTimeout(() => {
      setAnchorEl(null)
    }, 200)
  }

  //This function handles and detects click of popper menu
  const handleMenuClick = (buttonValue: number) => {
    handleCloseMenu()
    switch (buttonValue) {
      case 1:
        localStorage.removeItem('queryParams')
        localStorage.setItem('queryParams', cardData.code)
        saveModuleName(cardData)
        //Handle edit click
        navigate(`./${cardData.name}?${cardData.code}`)
        break
      case 2:
        //Handle delete click
        setOpenDeleteModal(true)
        break
    }
  }

  //This function will delete module on confirmation click
  const deleteOnConfirmation = (value: string) => {
    if (value === 'cancel') {
      setOpenDeleteModal(false)
    } else if (value === 'delete') {
      deleteModule({ id: cardData.code })
      setOpenDeleteModal(false)
    }
  }

  return (
    <>
      <StyledCard
        key={props.key}
        variant='outlined'
        image={cardData?.logoImagePath || BackImage}
        isactive={cardData?.activeFlag === true}
        height={'120px'}
      >
        <Stack marginTop={'80px'} justifyContent={'space-between'}>
          <Typography color={'white'} variant={'body2'}>
            {cardData.name}
          </Typography>
          <Stack justifyContent={'space-between'} classes={{ root: 'dot-box' }}>
            {cardData?.activeFlag === true && (
              <Box onMouseOver={handleOpenMenu} onMouseLeave={handleCloseMenu}>
                <WhiteMoreHorizIcon />
                {(!cardData.activeFlag || cardData.activeFlag === true) && (
                  <StyledPopper anchorEl={anchorEl} handleClick={handleMenuClick} />
                )}
              </Box>
            )}
          </Stack>
        </Stack>
      </StyledCard>
      <DeletePopup open={openDeleteModal} triggerAction={deleteOnConfirmation} />
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    deleteModule: (data: DeleteModuleTypes) => dispatch(deleteModule.request(data)),
    saveModuleName: (data: ModuleCardTypes) => dispatch(saveModuleName.request(data)),
  }
}
export default connect(null, mapDispatchToProps)(ModuleCards)
