import {
  Checkbox,
  FormControlLabel,
  ListItem,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  ModalButtonConatiner,
  ModalContainer,
  PopupButton,
  SmallTable,
  StyledList,
  StyledTextField,
  avtarStyle,
} from './UserManagementStyles'
import { ColumnStack, FullWidthStack, RedIcon } from '../Common/CommonStyles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { getAllClients } from '../../actions'
import { getAllClientsType } from '../../actions/Types'
import { clientManagementEntity } from '../../reducers'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { ClientAssignmentProps, ClientDTO } from './UserManagementTypes'
import BrokenImageHandler from '../Clients/BrokenImageHandler'

interface AddNewUserProps {
  getAllClients: (data?: getAllClientsType) => void
  allClients: { content: ClientDTO[] }
  handleClose: () => void
  handleAdd: (data: ClientDTO[]) => void
  selectedClients: ClientDTO[]
}
/* eslint-disable */

const AddClientModal = (Props: AddNewUserProps) => {
  const { t } = useTranslation()
  const { handleClose, handleAdd, getAllClients, allClients, selectedClients } = Props
  const [selectedClient, setSelectedClient] = useState<ClientDTO[] | []>(
    selectedClients ? selectedClients : [],
  )
  const [myClients, setMyClients] = useState<ClientDTO[] | []>([])

  //This use effect will set all clients to the satte after getting all clients
  useEffect(() => {
    if (allClients && allClients?.content?.length > 0) {
      setMyClients(allClients?.content)
    }
  }, [allClients])

  //This useEffect will get all clients from DB
  useEffect(() => {
    getAllClients({
      page: 0,
      size: 500,
    })
  }, [])

  //Functio to handle add client in modal
  const handleAddClient = (client: ClientDTO) => {
    client.newlyCreated = true
    const hasDuplicate = selectedClient.some((obj: { name: string }) => obj.name === client.name)
    if (!hasDuplicate) {
      setSelectedClient([...selectedClient, client])
    }
  }

  //Function to remove client from modal
  const handleRemoveClient = (client: { name: string }) => {
    const filteredObjects = selectedClient.filter(
      (obj: { name: string }) => obj.name !== client.name,
    )
    setSelectedClient([...filteredObjects])
  }

  return (
    <ModalContainer>
      <ColumnStack gap={'30px'}>
        <Typography variant='h6'>{t('add_client')}</Typography>
        <FullWidthStack gap={'20px'} justifyContent={'space-between'}>
          <ColumnStack justifyContent={'flex-start'} width={'48%'} height={'150px'}>
            <StyledTextField
              select
              label={`${t('select_client')}`}
              size='small'
              required={false}
              fullWidth
              multiline
            >
              <StyledList>
                {myClients?.length > 0 &&
                  myClients?.map((client: ClientDTO) => {
                    return (
                      <ListItem key={client.name} sx={{ '& label': { marginRight: '4px' } }}>
                        <FormControlLabel
                          sx={{ paddingLeft: '10px', '& span': { paddingRight: '0px' } }}
                          label={''}
                          control={
                            <Checkbox
                              onChange={() => handleAddClient(client)}
                              checked={
                                selectedClient?.length > 0 &&
                                selectedClient?.find(
                                  (item: { name: string }) => item.name === client.name,
                                ) &&
                                true
                              }
                            />
                          }
                        />
                        <Stack justifyContent={'space-between'} width={'70%'}>
                          <Stack gap={'5px'}>
                            <BrokenImageHandler
                              data={{ logo: client.logoImagePath, company_name: client.name }}
                              height={'30px'}
                              width={'30px'}
                              margin='0px 10px 0px 5px'
                              avtarStyle={avtarStyle}
                            />
                            {client.name}
                          </Stack>
                          {client.email}
                        </Stack>
                      </ListItem>
                    )
                  })}
              </StyledList>
            </StyledTextField>
          </ColumnStack>
          <ColumnStack width={'48%'} height={'150px'}>
            <TableContainer component={Paper}>
              <SmallTable>
                <TableHead>
                  <TableCell>{t('selected_client')}</TableCell>
                  <TableCell>{t('action')}</TableCell>
                </TableHead>
                <TableBody>
                  {selectedClient?.length > 0 &&
                    selectedClient?.map((client: { name: string }) => {
                      return (
                        <TableRow key={client.name}>
                          <TableCell>{client.name}</TableCell>
                          <TableCell align='center'>
                            <DeleteOutlineIcon
                              sx={RedIcon}
                              onClick={() => handleRemoveClient(client)}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </SmallTable>
            </TableContainer>
          </ColumnStack>
        </FullWidthStack>
        <ModalButtonConatiner>
          <PopupButton bgcolor={'#A90076'} onClick={() => handleAdd(selectedClient)}>
            {t('add')}
          </PopupButton>
          <PopupButton onClick={handleClose}>{t('cancel')}</PopupButton>
        </ModalButtonConatiner>
      </ColumnStack>
    </ModalContainer>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    allClients: clientManagementEntity.getClientManagementData(state).getAllClients,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllClients: (data?: getAllClientsType) => {
      dispatch(getAllClients.request(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddClientModal)
