import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import {
  ASSIGN_MODULE,
  ASSIGN_SUB_MODULE,
  CLIENT_DATA_FETCHED,
  CREATE_CLIENTS,
  DELETE_CLIENT,
  EDIT_CLIENT,
  GET_CLIENT_FEATURES,
  GET_CLIENT_MODULES,
  GET_SUB_MODULE,
  RESET,
  UPDATE_CLIENT,
} from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, FAILURE, GET_ALL_CLIENTS } = actionTypes

const ui = () => {
  const getAllClients = (state = false, action: actions) => {
    switch (action.type) {
      case GET_ALL_CLIENTS[SUCCESS]:
        return true
      case GET_ALL_CLIENTS[FAILURE]:
      case GET_ALL_CLIENTS[REQUEST]:
        return false
      default:
        return state
    }
  }
  const createClients = (state = false, action: actions) => {
    switch (action.type) {
      case CREATE_CLIENTS[SUCCESS]:
        return true
      case CREATE_CLIENTS[FAILURE]:
      case CREATE_CLIENTS[REQUEST]:
        return false
      case CREATE_CLIENTS[RESET]:
        return false
      default:
        return state
    }
  }

  const editClient = (state = false, action: actions) => {
    switch (action.type) {
      case EDIT_CLIENT[SUCCESS]:
        return true
      case EDIT_CLIENT[FAILURE]:
      case EDIT_CLIENT[REQUEST]:
        return false
      case EDIT_CLIENT[RESET]:
        return false
      default:
        return state
    }
  }

  const deleteClient = (state = false, action: actions) => {
    switch (action.type) {
      case DELETE_CLIENT[SUCCESS]:
        return true
      case DELETE_CLIENT[FAILURE]:
      case DELETE_CLIENT[REQUEST]:
        return false
      case DELETE_CLIENT[RESET]:
        return false
      default:
        return state
    }
  }

  const assignModule = (state = false, action: actions) => {
    switch (action.type) {
      case ASSIGN_MODULE[SUCCESS]:
        return true
      case ASSIGN_MODULE[FAILURE]:
      case ASSIGN_MODULE[REQUEST]:
        return false
      case ASSIGN_MODULE[RESET]:
        return false
      default:
        return state
    }
  }

  const assignSubModule = (state = false, action: actions) => {
    switch (action.type) {
      case ASSIGN_SUB_MODULE[SUCCESS]:
        return true
      case ASSIGN_SUB_MODULE[FAILURE]:
      case ASSIGN_SUB_MODULE[REQUEST]:
        return false
      case ASSIGN_SUB_MODULE[RESET]:
        return false
      default:
        return state
    }
  }

  const updateClient = (state = false, action: actions) => {
    switch (action.type) {
      case UPDATE_CLIENT[SUCCESS]:
        return true
      case UPDATE_CLIENT[FAILURE]:
      case UPDATE_CLIENT[REQUEST]:
        return false
      case UPDATE_CLIENT[RESET]:
        return false
      default:
        return state
    }
  }

  const getClientModules = (state = false, action: actions) => {
    switch (action.type) {
      case GET_CLIENT_MODULES[SUCCESS]:
        return true
      case GET_CLIENT_MODULES[FAILURE]:
      case GET_CLIENT_MODULES[REQUEST]:
        return false
      case GET_CLIENT_MODULES[RESET]:
        return false
      default:
        return state
    }
  }

  const clientDataStatus = (state = true, action: actions) => {
    switch (action.type) {
      case CLIENT_DATA_FETCHED[SUCCESS]:
        return false
      case CLIENT_DATA_FETCHED[RESET]:
        return true
      default:
        return state
    }
  }

  const getSubModules = (state = false, action: actions) => {
    switch (action.type) {
      case GET_SUB_MODULE[SUCCESS]:
        return true
      case GET_SUB_MODULE[FAILURE]:
      case GET_SUB_MODULE[REQUEST]:
        return false
      case GET_SUB_MODULE[RESET]:
        return false
      default:
        return state
    }
  }

  const getClientFeatures = (state = false, action: actions) => {
    switch (action.type) {
      case GET_CLIENT_FEATURES[SUCCESS]:
        return true
      case GET_CLIENT_FEATURES[FAILURE]:
      case GET_CLIENT_FEATURES[REQUEST]:
        return false
      case GET_CLIENT_FEATURES[RESET]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    getAllClients,
    createClients,
    editClient,
    deleteClient,
    assignModule,
    assignSubModule,
    updateClient,
    getClientModules,
    clientDataStatus,
    getSubModules,
    getClientFeatures,
  })
}

export default ui

export const getClientManagementData = (state: RootState) => state.ui.clientManagement
