import { Box, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { deleteSubModule, saveSubModuleDetails, saveUserDetails } from '../../actions'
import BackImage from '../../assets/images/Mask.jpg'
import { RootState } from '../../configureStore'
import { userManagementEntity } from '../../reducers'
import StyledPopper from '../Common/StyledPopper'
import { StyledCard, WhiteMoreHorizIcon } from '../Module Management/ModuleManagementComponents'
import {
  ColumnStack,
  FullWidthStack,
  SpaceBetweenStack,
  StyledCrossIcon,
  StyledCrossIconButton,
  StyledSubModuleIcon,
  StyledSubModuleIconButton,
  SubModuleBoxSx,
} from './CommonStyles'
import { ISubModuleCards } from './Types'

const SubModuleCards: React.FC<ISubModuleCards> = ({
  border,
  onClick,
  cardData,
  isActive,
  addSubModuleButton,
  onClickSubModule,
  onlyName,
  deleteSubModule,
  saveSubModuleDetails,
  key,
  backgroundimg,
  savedUserDetails,
  saveUserDetails,
  crossIcon,
  onClickCrossButton,
}: ISubModuleCards) => {
  const [anchorEl, setAnchorEl] = useState<null | any>(null)
  const [checked, setChecked] = React.useState(isActive === false ? false : true)
  const navigate = useNavigate()
  //Handling module if it is active or inActive
  const handleActive = () => {
    if (addSubModuleButton || onlyName) setChecked(!checked)
  }

  //This function will open popper menu while hover on more icon
  const handleOpenMenu = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget)
  }

  //This function will close popper menu while mouse out from more icon
  const handleCloseMenu = () => {
    setTimeout(() => {
      setAnchorEl(null)
    }, 200)
  }

  //This function handles and detects click of popper menu
  const handleMenuClick = (buttonValue: number) => {
    handleCloseMenu()
    switch (buttonValue) {
      case 1:
        saveSubModuleDetails(cardData)
        navigate(`./${cardData.name}`)
        //Handle edit click
        break
      case 2:
        //Handle delete click
        // deleteSubmodule()
        break
    }
  }

  return (
    <Box
      sx={checked && border ? SubModuleBoxSx : { p: 0.5 }}
      id={key !== undefined ? key.toString() : ''}
      key={key}
    >
      <StyledCard
        id={key !== undefined ? key.toString() : ''}
        key={key}
        variant='outlined'
        image={backgroundimg ? backgroundimg : BackImage}
        onError={() => console.log('working bro')}
        // isActive={isActive === false ? false : true}
        isactive={checked}
        height={'110px'}
        cursorpointer={addSubModuleButton === true || onlyName === true}
        onClick={() => {
          handleActive()
          if (onClick) {
            onClick(!checked, cardData)
          }
        }}
      >
        <ColumnStack height={'100%'} justifyContent={'space-between'}>
          <FullWidthStack flexDirection={'row-reverse'}></FullWidthStack>
          <FullWidthStack justifyContent={'space-between'}>
            <Stack>
              <Typography color={'white'} variant={'body2'}>
                {cardData?.name}
              </Typography>
            </Stack>
            {addSubModuleButton && checked ? (
              <>
                <StyledSubModuleIconButton onClick={onClickSubModule}>
                  <StyledSubModuleIcon />
                </StyledSubModuleIconButton>
              </>
            ) : addSubModuleButton ? (
              ''
            ) : onlyName ? (
              ''
            ) : crossIcon ? (
              <>
                <StyledCrossIconButton onClick={onClickCrossButton}>
                  <StyledCrossIcon />
                </StyledCrossIconButton>
              </>
            ) : (
              <SpaceBetweenStack classes={{ root: 'dot-box' }}>
                <Box onMouseOver={handleOpenMenu} onMouseLeave={handleCloseMenu}>
                  <WhiteMoreHorizIcon sx={{ cursor: 'pointer' }} />
                  {!cardData?.status || cardData?.status === 'active' ? (
                    <StyledPopper anchorEl={anchorEl} handleClick={handleMenuClick} />
                  ) : null}
                </Box>
              </SpaceBetweenStack>
            )}
          </FullWidthStack>
        </ColumnStack>
      </StyledCard>
    </Box>
  )
}

SubModuleCards.propTypes = {
  cardData: PropTypes.shape({
    isActive: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    docId: PropTypes.string.isRequired,
  }).isRequired,
  includeButton: PropTypes.bool,
  isActive: PropTypes.bool,
  key: PropTypes.number,
  saveSubModuleDetails: PropTypes.func.isRequired,
  deleteSubModule: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onClickCrossButton: PropTypes.func,
}
const mapStateToProps = (state: RootState) => {
  return {
    savedUserDetails: userManagementEntity.getUsers(state).saveUserDetails,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    saveSubModuleDetails: (data: any) => dispatch(saveSubModuleDetails.request(data)),
    deleteSubModule: (data: any) => dispatch(deleteSubModule.request(data)),
    saveUserDetails: (data: any) => dispatch(saveUserDetails.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SubModuleCards)
