import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
const { REQUEST, GET_USER_DATA } = actionTypes

//write sagas function

function* handlegetUserData() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getUserDataEndPoint,
    )
    yield sendPayload(apiResponse, GET_USER_DATA)
  } catch (e) {
    yield sendPayloadFailure(e, GET_USER_DATA)
  }
}

export const sagas = {
  //watcher come here
  watchgetUserData: takeLatest(actionTypes.GET_USER_DATA[REQUEST], handlegetUserData),
}
