import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { Avatar, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MainLogo from '../../assets/images/logo.png'
import { CustomTab, CustomTabs } from '../Module Management/ModuleManagementComponents'
import { FullWidthStack, TopBarUserNameStyle } from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import {
  createModule,
  createSubModule,
  getModuleById,
  getUserData,
  getUserLogout,
  saveModuleName,
  updateModule,
} from '../../actions'
import { connect } from 'react-redux'
import { dashboardEntity } from '../../reducers'
import { RootState } from '../../configureStore'
import { LogoBox } from './TopBarStyles'

interface TopBarProps {
  logout: () => void
  resetModuleName: () => void
  resetUpdateModule: () => void
  resetModule: () => void
  resetSearchedModule: () => void
  resetSubModule: () => void
  getUserData: () => void
  userData: { firstName: string; lastName: string }
  userDataReset: () => void
}

const TopBar = (props: TopBarProps) => {
  const {
    logout,
    resetModuleName,
    resetUpdateModule,
    resetModule,
    resetSearchedModule,
    resetSubModule,
    getUserData,
    userData,
    userDataReset,
  } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  React.useEffect(() => {
    if (!userData.firstName) {
      getUserData()
    }
  }, [])

  //This function will set selected tab
  const handlePath = () => {
    const pathnames = location?.pathname?.split('/')
    switch (pathnames[1]) {
      case 'users':
        return 2
      case 'module':
        return 0
      case 'clients':
        return 1
    }
  }

  const [value, setValue] = React.useState(handlePath())
  //This function handle change of tabs and navigation
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    handleClearData()
    setValue(newValue)
    switch (newValue) {
      case 0:
        navigate('/module')
        break
      case 1:
        navigate('/clients')
        break
      case 2:
        navigate('/users')
        break
    }
  }

  //This will clear all data related to module
  const handleClearData = () => {
    localStorage.removeItem('queryParams')
    resetModuleName()
    resetUpdateModule()
    resetModule()
    resetSearchedModule()
    resetSubModule()
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    setAnchorEl(null)
    logout()
    toast.success(t('logout_success'))
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('token_expire_time')
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('secretKey')
    userDataReset()
    navigate('/')
  }
  const isMenuOpen = Boolean(anchorEl)
  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>{t('profile')}</MenuItem>
      <MenuItem onClick={handleLogout}>{t('log_out')}</MenuItem>
    </Menu>
  )
  return (
    <>
      {' '}
      <AppBar position='static' color='inherit'>
        <Toolbar>
          <Stack justifyContent={'space-between'} width={'100%'}>
            <Stack>
              <Box
                component={'img'}
                src={MainLogo}
                sx={LogoBox}
                onClick={() => {
                  navigate('/module')
                  setValue(0)
                }}
              ></Box>
              <FullWidthStack ml={'50px'} mt={'15px'}>
                <CustomTabs indicatorColor='secondary' onChange={handleChange} value={value}>
                  <CustomTab label={t('modules')} />
                  <CustomTab label={t('clients')} />
                  <CustomTab label={t('users')} />
                </CustomTabs>
              </FullWidthStack>
            </Stack>
            <Stack>
              <Stack paddingRight={'30px'}>
                <IconButton size='large' aria-label='show 7 new notifications' color='inherit'>
                  <Badge color='error'>
                    <NotificationsNoneIcon />
                  </Badge>
                </IconButton>
              </Stack>
              <Typography sx={TopBarUserNameStyle}>
                {' '}
                {userData.firstName ? userData.firstName + ' ' + userData.lastName : 'Admin'}
              </Typography>
              <IconButton
                size='large'
                edge='end'
                aria-label='account of current user'
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
                color='inherit'
              >
                <Avatar />
              </IconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: dashboardEntity.getDashboard(state).getUserData,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    logout: () => dispatch(getUserLogout.request()),
    resetModuleName: () => dispatch(saveModuleName.reset()),
    resetUpdateModule: () => dispatch(updateModule.reset()),
    resetModule: () => dispatch(createModule.reset()),
    resetSearchedModule: () => dispatch(getModuleById.reset()),
    resetSubModule: () => dispatch(createSubModule.reset()),
    getUserData: () => dispatch(getUserData.request()),
    userDataReset: () => dispatch(getUserData.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)
