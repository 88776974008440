import { InputAdornment, Link, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import LanguageDropdown from '../Common/LanguageDropdown'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { isLoggedIn } from '../../auth/Auth'
import {
  Heading,
  LangDrop,
  LeftContainer,
  LoginButton,
  Logo,
  MainContainer,
  RememberMeOuterDiv,
  RightContainer,
} from './Styles'
import { connect, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ColumnStack, CursorPointer, alignItemFlexStart } from '../Common/CommonStyles'
import banner from '../../assets/images/uniqus_bannerv2.png'
import { userAuthenticationUI } from '../../reducers'
import { getForgotPassword } from '../../actions'

type ForgotPasswordType = {}

const ForgotPassword = ({}: ForgotPasswordType) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [validEmailMessage, setValidEmailMessage] = useState('')

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/module')
      toast.success(t('login_success'))
    }
  }, [navigate])

  //Checking valid email
  const isValidEmail = (email: string): boolean => {
    const emailRegex: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    return emailRegex.test(email)
  }

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const setForgotPassword = useSelector(
    (state: any) => userAuthenticationUI.getUserAuthentication(state).getForgotPassword,
  )

  const handlePasswordInput = () => {
    dispatch(getForgotPassword.request({ email: email }))
  }

  useEffect(() => {
    if (setForgotPassword) {
      toast.success(t('new_password_message'))
      navigate('/')
    }
  }, [setForgotPassword])

  const handleSubmit = () => {
    if (email === '') {
      toast.error(t('fill_email'))
    } else {
      if (isValidEmail(email)) {
        setValidEmailMessage('')
      } else {
        setValidEmailMessage(`${t('valid_email')}`)
        return
      }
      handlePasswordInput()
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <MainContainer>
      <LeftContainer src={banner}></LeftContainer>
      <RightContainer>
        <LangDrop>
          <LanguageDropdown />
        </LangDrop>
        <Logo src='./images/logo.png'></Logo>
        <ColumnStack mb={1.2}>
          <Heading fontsize={'28px'}>{t('forgot_password')}</Heading>
          <Typography fontSize={'12px'} color={'#999999'}>
            {t('forgot_pass_msg')}
          </Typography>
        </ColumnStack>
        <TextField
          id='email'
          label={t('email_address')}
          name='email'
          autoComplete='email'
          autoFocus
          value={email}
          onKeyPress={handleKeyPress}
          onChange={handleEmailInput}
          helperText={validEmailMessage}
          error={validEmailMessage.length > 0}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <MailOutlineIcon />
              </InputAdornment>
            ),
          }}
        />
        <LoginButton fullWidth onClick={handleSubmit}>
          {t('send_email')}
        </LoginButton>
      </RightContainer>
    </MainContainer>
  )
}

export default ForgotPassword
