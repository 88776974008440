import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { Fade, List, ListItemButton, ListItemText, Popper } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BoldIcon, ListButton, PopperContainer, RedIcon, StyledListItemIcon } from './CommonStyles'
import { IStyledPopper } from './Types'

const StyledPopper = ({ anchorEl, handleClick }: IStyledPopper) => {
  const { t } = useTranslation()
  return (
    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <PopperContainer>
            <List>
              <ListItemButton onClick={() => handleClick(1)} sx={ListButton}>
                <StyledListItemIcon>
                  <ModeEditIcon sx={BoldIcon} />
                </StyledListItemIcon>
                <ListItemText>{t('edit')}</ListItemText>
              </ListItemButton>
              <ListItemButton onClick={() => handleClick(2)} sx={ListButton}>
                <StyledListItemIcon>
                  <DeleteOutlineIcon sx={RedIcon} />
                </StyledListItemIcon>
                <ListItemText>{t('delete')}</ListItemText>
              </ListItemButton>
            </List>
          </PopperContainer>
        </Fade>
      )}
    </Popper>
  )
}
StyledPopper.propTypes = {
  anchorEl: PropTypes.element,
  handleClick: PropTypes.func.isRequired,
}

export default StyledPopper
