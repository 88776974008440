import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import {
  FAILURE,
  FORGOT_PASSWORD,
  GET_CLIENT_KEYS,
  RESET,
  RESET_PASSWORD,
} from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, LOGIN } = actionTypes

const entity = () => {
  const getUserAuthentication = (state = {}, action: actions) => {
    switch (action.type) {
      case LOGIN[SUCCESS]:
        return action.payload
      case LOGIN[REQUEST]:
        return {}
      case LOGIN[FAILURE]:
        return action.payload
      default:
        return state
    }
  }

  const getClientKeys = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_CLIENT_KEYS[SUCCESS]:
        return action.payload
      case GET_CLIENT_KEYS[REQUEST]:
        return {}
      case GET_CLIENT_KEYS[FAILURE]:
        return action.payload
      default:
        return state
    }
  }

  const getForgotPassword = (state = {}, action: actions) => {
    switch (action.type) {
      case FORGOT_PASSWORD[SUCCESS]:
        return action.payload
      case FORGOT_PASSWORD[REQUEST]:
        return {}
      case FORGOT_PASSWORD[FAILURE]:
        return {}
      case FORGOT_PASSWORD[RESET]:
        return {}
      default:
        return state
    }
  }

  const getResetPassword = (state = {}, action: actions) => {
    switch (action.type) {
      case RESET_PASSWORD[SUCCESS]:
        return action.payload
      case RESET_PASSWORD[REQUEST]:
        return {}
      case RESET_PASSWORD[FAILURE]:
        return {}
      case RESET_PASSWORD[RESET]:
        return {}
      default:
        return state
    }
  }

  return combineReducers({
    getUserAuthentication,
    getClientKeys,
    getForgotPassword,
    getResetPassword,
  })
}

export default entity

export const getUserAuthentication = (state: RootState) => state.entities.userAuthentication
