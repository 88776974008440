import React from 'react'
import NoDataImage from '../../assets/images/no_data.png'
import { FullSizeCenterStack } from './CommonStyles'
const NoData = () => {
  return (
    <FullSizeCenterStack>
      <img src={NoDataImage} height={'90%'} />
    </FullSizeCenterStack>
  )
}
export default NoData
