import React, { useEffect, useState } from 'react'
import { MainContainer } from '../Module Management/ModuleManagementComponents'
import StyledBreadCrumb from '../Common/StyledBreadCrumb'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { AssignedModuleContainer, ClientCard, Line, avtarStyle } from './UserManagementStyles'
import { ColumnStack, FullSizeStack, FullWidthStack } from '../Common/CommonStyles'
import UserManagementImage from '../../assets/images/user_management.png'
import ModuleManagementImage from '../../assets/images/product-unit-line-icon-marketing-260nw-1371853472@2x.png'
import SubModuleCard from '../Common/SubModuleCard'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { saveUserDetails } from '../../actions'
import { userManagementEntity } from '../../reducers'
import { paperMessageSx } from '../Clients/ClientsStyles'
import { useTranslation } from 'react-i18next'
import { Background } from '../../utils'
import { ClientDTO, ModuleDTO, SavedUserDetailsType, SubModuleDTO } from './UserManagementTypes'
import BrokenImageHandler from '../Clients/BrokenImageHandler'

interface AssignModuleClientProps {
  savedUserDetails: SavedUserDetailsType
  saveUserDetails: (data: SavedUserDetailsType) => void
}

const AssignModuleClient = (props: AssignModuleClientProps) => {
  const { savedUserDetails, saveUserDetails } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const userId = localStorage.getItem('searchedUser')
  const [selectedClient, setSelectedClient] = useState<ClientDTO | null>(null)
  const [avalibleModules, setAvalibleModules] = useState<ModuleDTO[] | []>([])
  const [selectedModule, setSelectedModule] = useState<ModuleDTO[] | []>(
    savedUserDetails.assignedModule ? savedUserDetails.assignedModule : [],
  )
  const [activeModules, setActiveModules] = useState<ModuleDTO | null>(null)
  const [selectedSubModule, setSelectedSubModule] = useState<SubModuleDTO[] | []>(
    savedUserDetails.assignedSubModule ? savedUserDetails.assignedSubModule : [],
  )

  useEffect(() => {
    if (!savedUserDetails?.clientList) {
      navigate('/users/adduser')
    }
    const handleRefresh = (event: any) => {
      event.preventDefault()
      event.returnValue = ''
    }
    window.addEventListener('beforeunload', handleRefresh)
    return () => {
      window.removeEventListener('beforeunload', handleRefresh)
    }
  }, [])

  const handleAddModule = () => {
    saveUserDetails({
      ...savedUserDetails,
      selectedClient,
      assignedModule: selectedModule,
      assignedSubModule: selectedSubModule,
    })
    if (userId) {
      navigate('/users/edituser')
    } else {
      navigate('/users/adduser')
    }
  }

  const handleClientSelect = (client: ClientDTO) => {
    setSelectedClient(client)
    setAvalibleModules(client.modules)
    setActiveModules(null)
  }

  const handleModuleSelect = (module: ModuleDTO) => {
    module.clientInfo = selectedClient
    module.newlyCreated = true
    const updatedSelectedModule = selectedModule?.filter(
      (obj: { docId: string }) => obj.docId !== module.docId,
    )
    if (updatedSelectedModule.length === selectedModule?.length) {
      updatedSelectedModule.push(module)
      setActiveModules(module)
    } else {
      setActiveModules(null)
    }
    setSelectedModule(updatedSelectedModule)
  }

  const handleSubModuleSelect = (submodule: SubModuleDTO) => {
    if (activeModules) submodule.parentModule = activeModules
    submodule.newlyCreated = true
    const updatedSelectedSubModule = selectedSubModule.filter(
      (obj: { docId: string }) => obj.docId !== submodule.docId,
    )
    if (updatedSelectedSubModule.length === selectedSubModule.length) {
      updatedSelectedSubModule.push(submodule)
    }
    setSelectedSubModule(updatedSelectedSubModule)
  }

  return (
    <MainContainer>
      <StyledBreadCrumb handleDone={handleAddModule} />
      <Stack
        direction={'row'}
        height={'90%'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        mt={'10px'}
        gap={'10px'}
      >
        <AssignedModuleContainer>
          <ColumnStack height={'50%'} justifyContent={'flex-start'} width={'100%'}>
            <FullWidthStack height={'35px'} gap={'10px'}>
              <img src={UserManagementImage} alt='' height={'20px'} />
              <Typography>Client List</Typography>
            </FullWidthStack>
            <FullWidthStack
              justifyContent={'flex-start'}
              paddingLeft={'30px'}
              mt={'10px'}
              gap={'10px'}
            >
              {savedUserDetails.clientList &&
                savedUserDetails.clientList?.map((client: ClientDTO) => (
                  <ClientCard onClick={() => handleClientSelect(client)}>
                    <BrokenImageHandler
                      data={{ logo: client.logoImagePath, company_name: client.name }}
                      height={'40px'}
                      width={'40px'}
                      margin='0'
                    />
                  </ClientCard>
                ))}
            </FullWidthStack>
          </ColumnStack>
          <Line />
          <ColumnStack height={'50%'} justifyContent={'flex-start'} width={'100%'}>
            <FullWidthStack height={'35px'} gap={'10px'}>
              <img src={ModuleManagementImage} alt='' height={'24px'} />
              <Typography>{t('modules')}</Typography>
            </FullWidthStack>
            <FullSizeStack gap={'10px'} mt={'10px'}>
              {avalibleModules.length > 0 ? (
                avalibleModules?.map((module: ModuleDTO, index) => {
                  return (
                    <Box onClick={() => handleModuleSelect(module)} key={index + Date.now()}>
                      <SubModuleCard
                        cardData={module}
                        includeButton={false}
                        isActive={
                          selectedModule?.length > 0
                            ? selectedModule?.find(
                                (modules: ModuleDTO) => modules.docId === module.docId,
                              )
                              ? true
                              : false
                            : false
                        }
                        addSubModuleButton={false}
                        onClickSubModule={(e) => {
                          e.stopPropagation()
                        }}
                        onlyName={true}
                        backgroundimg={module.logoImagePath}
                      />
                    </Box>
                  )
                })
              ) : (
                <Paper elevation={0} sx={paperMessageSx}>
                  <Typography>{t('please_select_a_client')}</Typography>
                </Paper>
              )}
            </FullSizeStack>
          </ColumnStack>
        </AssignedModuleContainer>
        <ColumnStack
          height={'50%'}
          justifyContent={'flex-start'}
          width={'48%'}
          bgcolor={Background}
          padding={'10px 20px'}
          borderRadius={'10px'}
        >
          <FullWidthStack height={'35px'} gap={'10px'}>
            <img src={ModuleManagementImage} alt='' height={'24px'} />
            <Typography>{t('sub_modules')}</Typography>
          </FullWidthStack>
          <FullSizeStack gap={'10px'} mt={'10px'}>
            {activeModules && activeModules?.subModuleDTOS?.length ? (
              activeModules?.subModuleDTOS?.map((module: SubModuleDTO, index: number) => {
                return (
                  <Box onClick={() => handleSubModuleSelect(module)} key={index + Date.now()}>
                    <SubModuleCard
                      cardData={module}
                      includeButton={false}
                      backgroundimg={module.logoImagePath}
                      isActive={
                        selectedSubModule?.length > 0
                          ? selectedSubModule?.find(
                              (modules: SubModuleDTO) => modules.docId === module.docId,
                            )
                            ? true
                            : false
                          : false
                      }
                      addSubModuleButton={false}
                      onClickSubModule={(e) => {
                        e.stopPropagation()
                      }}
                      onlyName={true}
                    />
                  </Box>
                )
              })
            ) : (
              <Paper elevation={0} sx={paperMessageSx}>
                <Typography>{t('please_select_a_module')}</Typography>
              </Paper>
            )}
          </FullSizeStack>
        </ColumnStack>
      </Stack>
    </MainContainer>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    savedUserDetails: userManagementEntity.getUsers(state).saveUserDetails,
    savedModuleDetails: userManagementEntity.getUsers(state).saveModuleDetailsForUser,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    saveUserDetails: (data: SavedUserDetailsType) => dispatch(saveUserDetails.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignModuleClient)
