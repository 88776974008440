import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AssignModulePaper,
  SelectTypography,
  StyledImg,
  StyledStackAssignModule,
} from './ClientsStyles'
import ModuleManagementIcon from '../../assets/images/product-unit-line-icon-marketing-260nw-1371853472@2x.png'
import StyledBreadCrumb from '../Common/StyledBreadCrumb'
import { Stack, Typography } from '@mui/material'
import SubModuleCards from '../Common/SubModuleCard'
import { clientManagementEntity, clientManagementUI } from '../../reducers'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { clientAssignModules, getSubModule } from '../../actions'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getSubModuleRequestType, modulesType } from '../../actions/Types'

interface AssignSubModule {
  subMoudleData: modulesType[]
  fetchSubModule: (data: getSubModuleRequestType) => void
  ClientAssignModuleData: modulesType[]
  subModuleStatus: boolean
  addModules: (data: modulesType[]) => void
  resetSubModule: () => void
}

const AssignSubModule = (props: AssignSubModule) => {
  const moduleID = localStorage.getItem('moduleId')
  const {
    subMoudleData,
    fetchSubModule,
    ClientAssignModuleData,
    addModules,
    subModuleStatus,
    resetSubModule,
  } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activeSubModules, setActiveSubModules] = useState<modulesType[] | []>([])
  const mainModule = ClientAssignModuleData.filter(
    (module: modulesType) => module.code === moduleID,
  )[0]?.subModuleDTOS
  const [selectedModule, setSelectedModule] = useState<modulesType[] | []>(
    mainModule ? mainModule : [],
  )
  useEffect(() => {
    if (moduleID) {
      fetchSubModule({ code: moduleID })
    }
  }, [])
  useEffect(() => {
    if (subModuleStatus) {
      setActiveSubModules(
        subMoudleData.filter((sModule: modulesType) => {
          sModule.assigned = true
          return sModule.activeFlag
        }),
      )
    }
  }, [subModuleStatus])

  useEffect(() => {
    const handleRefresh = (event: any) => {
      event.preventDefault()
      event.returnValue = '' // Required for Chrome
    }

    window.addEventListener('beforeunload', handleRefresh)

    return () => {
      window.removeEventListener('beforeunload', handleRefresh)
    }
  }, [])

  const checkModuleStatus = (status: boolean, module: any) => {
    if (status === true) {
      setSelectedModule((selectedModule: modulesType[]) => [...selectedModule, module])
    } else if (status === false) {
      setSelectedModule((selectedModule: modulesType[]) => {
        return selectedModule.filter(
          (selectModule: modulesType) => selectModule.code !== module.code,
        )
      })
    }
  }

  const handleAddModule = () => {
    if (moduleID) {
      addModules(
        ClientAssignModuleData.map((module: modulesType) => {
          if (module.code === moduleID) {
            module.subModuleDTOS = selectedModule
          }
          return module
        }),
      )
    }
    setActiveSubModules([])
    resetSubModule()
    localStorage.removeItem('moduleId')
    navigate(-1)
  }

  const handleBack = () => {
    localStorage.removeItem('moduleId')
    resetSubModule()
    setActiveSubModules([])
  }
  return (
    <AssignModulePaper>
      <StyledBreadCrumb handleDone={handleAddModule} handleBack={handleBack} />
      <Stack spacing={1} mt={3} mb={1}>
        <StyledImg src={ModuleManagementIcon} />
        <Typography sx={SelectTypography}>{t('submodule')}</Typography>
      </Stack>
      <StyledStackAssignModule direction={'row'}>
        {activeSubModules.length > 0 &&
          activeSubModules?.map((module: any) => {
            const active = selectedModule.find((subModule: modulesType) => {
              return subModule.code === module.code
            })
            return (
              <SubModuleCards
                onClick={checkModuleStatus}
                cardData={module}
                includeButton={false}
                isActive={Boolean(active)}
                backgroundimg={module.logoImagePath}
                onlyName={true}
              />
            )
          })}
      </StyledStackAssignModule>
    </AssignModulePaper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    subMoudleData: clientManagementEntity.getClientManagementData(state).getSubModules,
    subModuleStatus: clientManagementUI.getClientManagementData(state).getSubModules,
    ClientAssignModuleData:
      clientManagementEntity.getClientManagementData(state).clientAssignModules,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchSubModule: (data: getSubModuleRequestType) => dispatch(getSubModule.request(data)),
    addModules: (data: modulesType[]) => dispatch(clientAssignModules.addModules(data)),
    resetSubModule: () => dispatch(getSubModule.reset()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AssignSubModule)
