import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { FC, useEffect, useState } from 'react'
import { DropTargetMonitor, useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Dispatch } from 'redux'
import { uploadImage } from '../../actions'
import UploadLogo from '../../assets/images/photo@2x.jpg'
import UniqusLogo from '../../assets/images/uniquslogo.png'
import { RootState } from '../../configureStore'
import { uploadImageEntity, uploadImageUI } from '../../reducers'
import { Image, StyledButton } from '../Module Management/ModuleManagementComponents'
import {
  CenterStack,
  DropZoneContainer,
  DropZoneLogo,
  LogoProps,
  ReplaceButton,
  RightContainer,
  UploadButton,
} from './CommonStyles'

interface FileDropzoneProps {
  onDrop: (imageUrl: string) => void
  height?: string
  UploadImgData: (data: {}) => void
  coverImageUrl?: string
  uploadImgInfo: {
    url: string
  }
  resetImageData: () => void
}

const DragDrop: FC<FileDropzoneProps> = (props: FileDropzoneProps) => {
  const { onDrop, height, UploadImgData, coverImageUrl, uploadImgInfo, resetImageData } = props
  const { t } = useTranslation()
  const [imgSrc, setImgSrc] = useState(coverImageUrl ? coverImageUrl : '')
  const [imageError, setImageError] = useState(false)

  const handleImageError = () => {
    setImageError(true)
  }

  useEffect(() => {
    setImageError(false)
  }, [imgSrc])

  useEffect(() => {
    setImgSrc(coverImageUrl ? coverImageUrl : '')
  }, [coverImageUrl])

  //This sueEffect will trigger file url change and will set the URL
  useEffect(() => {
    if (uploadImgInfo.url) {
      setImgSrc(uploadImgInfo.url)
      onDrop(uploadImgInfo.url)
      resetImageData()
    }
  }, [uploadImgInfo])

  useEffect(() => {
    return () => {
      setImgSrc('')
    }
  }, [])

  //This function will handle button click to select image and will call generateFileUrl to generate URL
  const handleButtonClick = (event: any): void => {
    const file = event.target.files[0]
    if (file && file.size > 10485760) {
      event.target.value = null
      toast.error(`${t('logo_error')}`)
      return
    }
    if (Boolean(file)) generateFileUrl(file)
    else event.target.value = null
  }

  //This function will call file url generate action which is responsible for gettig image URL
  const generateFileUrl = (file: File | { size: number }) => {
    UploadImgData({ file: file })
  }

  //This function will delete already exist cover image or selected cover image
  const handleDeleteImage = () => {
    setImgSrc('')
    resetImageData()
    onDrop('')
  }

  //This funtion will handle file drag and drop and call generateFileUrl to generate URL
  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (item: any, monitor: DropTargetMonitor) => {
      if (monitor) {
        const files = item.files as File[]
        generateFileUrl(files[0])
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return (
    <DropZoneContainer ref={drop} height={height}>
      {imgSrc === '' ? (
        <>
          <DropZoneLogo>
            <Image src={UploadLogo} />
            <Typography variant='caption'>{t('drag_and_drop')}</Typography>
          </DropZoneLogo>
          <Typography variant='body2' width={'15px'}>
            {t('or')}
          </Typography>
          <CenterStack width={'45%'}>
            <UploadButton>
              <input hidden accept='image/*' multiple type='file' onChange={handleButtonClick} />
              {t('browse_files')}
            </UploadButton>
          </CenterStack>
        </>
      ) : (
        <Grid container>
          <Grid item xs={12} sm={6} pt={'10px'}>
            {imageError ? (
              <img src={UniqusLogo} style={LogoProps} />
            ) : (
              <img src={imgSrc} onError={() => handleImageError()} style={LogoProps} />
            )}
          </Grid>
          <RightContainer item xs={12} sm={6}>
            <ReplaceButton>
              <AddIcon />

              <input hidden accept='image/*' multiple type='file' onChange={handleButtonClick} />
              {t('replace')}
            </ReplaceButton>
            <StyledButton
              startIcon={<DeleteOutlineIcon />}
              variant='outlined'
              onClick={handleDeleteImage}
            >
              {t('delete')}
            </StyledButton>
          </RightContainer>
        </Grid>
      )}
    </DropZoneContainer>
  )
}
const mapStateToProps = (state: RootState) => {
  return {
    uploadImgInfo: uploadImageEntity.getUploadImage(state).uploadImage,
    uploadImgPass: uploadImageUI.getUploadImage(state).uploadImage,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    UploadImgData: (data: object) => dispatch(uploadImage.request(data)),
    resetImageData: () => dispatch(uploadImage.reset()),
  }
}

DragDrop.propTypes = {
  onDrop: PropTypes.func.isRequired,
  height: PropTypes.string,
}
export default connect(mapStateToProps, mapDispatchToProps)(DragDrop)
