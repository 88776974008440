import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AssignModuleColumnStackSx,
  AssignModulePaper,
  SelectTypography,
  StyledImg,
  StyledStackAssignModule,
  checkboxLabelSx,
  featureHeaderBoxSx,
  featureHeaderSx,
  headerBoxSx,
  headerGridBox,
  parentCheckBoxSx,
  pesmissionsHeaderSx,
} from './ClientsStyles'
import { connect } from 'react-redux'
import { CenterStack, ColumnStack } from '../Common/CommonStyles'
import NoDataImage from '../../assets/images/no_data.png'
import ModuleManagementIcon from '../../assets/images/product-unit-line-icon-marketing-260nw-1371853472@2x.png'
import StyledBreadCrumb from '../Common/StyledBreadCrumb'
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import SubModuleCards from '../Common/SubModuleCard'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../configureStore'
import { clientManagementEntity, moduleManagementEntity } from '../../reducers'
import {
  clientAssignModules,
  deleteModule,
  featureIdInfo,
  getAllModules,
  getAssignedModules,
  getClientFeatures,
  getSubModule,
} from '../../actions'
import { Dispatch } from 'redux'
import { modulesType } from '../../actions/Types'
import { StyledHTMLImg } from '../Common/CommonStyles'
import { Background } from '../../utils'
import { toast } from 'react-toastify'

type permissionType = {
  featureName: string
  featurePermissionId: number
  id: string
  permissionName: string
  status: Boolean
  subFeatureName: string
}

type mapValueType = {
  featureName: string
  permissionResponseDTOS?: permissionType[]
  subFeatureName?: string | null
  id: string
  featureId?: number
}
interface AssignModule {
  getAllModules: () => void
  allModules: { content: [] }
  addModules: (data: modulesType[]) => void
  ClientAssignModuleData: modulesType[]
  mergSelectedModules: (data: modulesType[]) => void
  resetDeleted: () => void
  resetModule: () => void
  resetSubModule: () => void
  getClientFeaturesList: (data: { [Key: string]: string }) => void
  clientFeatureList: mapValueType[]
  featureIdInfo: string[]
  setFeatureIdInfo: (data: string[]) => void
}

const AssignModule = (props: AssignModule) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    getAllModules,
    allModules,
    addModules,
    ClientAssignModuleData,
    mergSelectedModules,
    resetModule,
    resetSubModule,
    getClientFeaturesList,
    clientFeatureList,
    setFeatureIdInfo,
    featureIdInfo,
  } = props
  const [activeModule, setActiveModule] = useState([])
  const [selectedModule, setSelectedModule] = useState<modulesType[] | []>(ClientAssignModuleData)
  const [subModules, setSubModules] = useState<modulesType[] | []>([])
  const [moduleId, setModuleId] = useState<string>('')
  const [subModuleDto] = useState<Map<string, modulesType[] | []>>(new Map())
  const [subModuleSelected, setSubModuleSelected] = useState<modulesType[] | []>([])
  const [featurePermissionMap, setFeaturePermissionMap] = useState<Map<string, mapValueType[]>>(
    new Map(),
  )
  const [maxLength, setMaxLength] = useState(0)
  const [featureIds, setFeatureIds] = useState<string[]>(featureIdInfo)
  const [arcModuleId, setArcModuleId] = useState('')

  useEffect(() => {
    if (ClientAssignModuleData.length) {
      setModuleId(ClientAssignModuleData[0]?.code)
      getClientFeaturesList({ moduleName: ClientAssignModuleData[0]?.name })
    }
  }, [])

  useEffect(() => {
    const length = { length: 0 }
    if (featurePermissionMap.size)
      featurePermissionMap.forEach((v, k) => {
        if (v?.length > length.length) length.length = v?.length
      })
    setMaxLength(length.length)
  }, [featurePermissionMap])

  useEffect(() => {
    if (activeModule.length) {
      activeModule.forEach((module: modulesType) => {
        if (module?.name === 'ARC') setArcModuleId(module.code)
        if (module?.name === 'GRC') setArcModuleId(module.code)
      })
    }
  }, [activeModule])

  useEffect(() => {
    if (clientFeatureList.length) {
      featurePermissionMap.clear()
      clientFeatureList?.forEach((data: mapValueType, i: number) => {
        const mapObj = featurePermissionMap.get(data?.featureName)
        mapObj !== undefined
          ? featurePermissionMap.set(data?.featureName, [
              ...mapObj,
              {
                featureName: data?.subFeatureName ?? '',
                id: data?.id,
              },
            ])
          : featurePermissionMap.set(data?.featureName, [
              {
                featureName: data?.subFeatureName ?? '',
                id: data?.id,
              },
            ])
        if (i === clientFeatureList.length - 1) {
          setFeaturePermissionMap(new Map(featurePermissionMap))
        }
      })
    }
  }, [clientFeatureList])

  useEffect(() => {
    getAllModules()
    resetSubModule()
  }, [])
  useEffect(() => {
    if (Object.keys(allModules).length !== 0) {
      setActiveModule(
        allModules?.content?.filter((module: modulesType) => {
          module.assigned = true
          module.subModuleDTOS = module.subModuleDTOS?.length
            ? module.subModuleDTOS.filter((subModule: modulesType) => {
                subModule.assigned = true
                return subModule.activeFlag
              })
            : []
          return module.activeFlag === true
        }),
      )
    }
  }, [allModules])

  useEffect(() => {
    const handleRefresh = (event: any) => {
      event.preventDefault()
      event.returnValue = '' // Required for Chrome
    }
    window.addEventListener('beforeunload', handleRefresh)
    return () => {
      window.removeEventListener('beforeunload', handleRefresh)
    }
  }, [])

  useEffect(() => {
    setFeatureIdInfo([...featureIds])
  }, [featureIds])

  //Setting sub module DTO
  if (activeModule.length > 0 && subModuleDto.size === 0) {
    activeModule.forEach((sbDTO: modulesType) => {
      const subModuleDTO = ClientAssignModuleData.find(
        (obj: modulesType) => obj.code === sbDTO.code,
      )
      if (subModuleDTO) subModuleDto.set(sbDTO.code, subModuleDTO.subModuleDTOS)
      else subModuleDto.set(sbDTO.code, [])
    })
  }

  //On click Sub module
  const handleOnClickSubModule = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: modulesType,
  ) => {
    event.stopPropagation()
    addModules(selectedModule)
    localStorage.setItem('moduleId', module.code)
    navigate(`assignsubmodule`)
  }

  //checking Module status
  const checkModuleStatus = (status: boolean, module: modulesType) => {
    if (status) getClientFeaturesList({ moduleName: module?.name })
    if (status === true) {
      setModuleId(module?.code)
      const submoduleSelected = subModuleDto.get(module?.code)
      if (submoduleSelected !== undefined) {
        setSubModuleSelected(submoduleSelected)
      } else setSubModuleSelected([])
      setSelectedModule((selectedModule: modulesType[]) => {
        return [...selectedModule, module]
      })
      setSubModules(module.subModuleDTOS)
    } else if (status === false) {
      if (moduleId === module.code) {
        setSubModules([])
        setModuleId('')
      }
      setSelectedModule((selectedModule: modulesType[]) => {
        return selectedModule.filter(
          (selectModule: modulesType) => selectModule.code !== module.code,
        )
      })
    }
  }

  //checking sub module status
  const checkSubModuleStatus = (status: boolean, subModule: modulesType) => {
    const temp = subModuleDto.get(moduleId)
    if (status === true) {
      if (temp !== undefined) subModuleDto.set(moduleId, [...temp, subModule])
    } else if (status === false) {
      const temp = subModuleDto.get(moduleId)
      if (temp !== undefined)
        subModuleDto.set(
          moduleId,
          temp.filter((sbDTO: modulesType) => sbDTO.code !== subModule.code),
        )
    }
  }

  const handleAssignedModule = () => {
    const flag = { flag: false }
    mergSelectedModules(
      selectedModule.map((modules: modulesType) => {
        const subModuleArray = subModuleDto.get(modules?.code)
        if (modules?.name === 'ARC') flag.flag = true
        if (modules?.name === 'GRC') flag.flag = true
        if (subModuleArray !== undefined) modules.subModuleDTOS = subModuleArray
        return modules
      }),
    )
    if (featureIdInfo.length === 0 && flag?.flag) {
      toast.error(t('feature_error_message'))
      return
    }
    navigate(`/clients/details`)
  }

  const handleResetAssignedModule = () => {
    resetModule()
  }

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>, status?: boolean) => {
    if (status)
      setFeatureIds((prev: string[]) => {
        return [...prev, event?.target?.id]
      })
    else
      setFeatureIds((prev: string[]) => {
        return prev.filter((id: string) => {
          return id !== event.target.id
        })
      })
  }

  const handleParentbox = (event: ChangeEvent<HTMLInputElement>, status?: boolean) => {
    if (status && clientFeatureList?.length) {
      setFeatureIds(clientFeatureList?.map((feature: mapValueType) => feature.id.toString()))
    } else setFeatureIds([])
  }

  return (
    <AssignModulePaper>
      <StyledBreadCrumb
        handleDone={handleAssignedModule}
        handleBack={handleResetAssignedModule}
      ></StyledBreadCrumb>
      <ColumnStack sx={AssignModuleColumnStackSx}>
        <Stack spacing={1}>
          <StyledImg src={ModuleManagementIcon} />
          <Typography sx={SelectTypography}>{t('modules')}</Typography>
        </Stack>
        <StyledStackAssignModule>
          {activeModule?.length !== 0 ? (
            activeModule.map((module: modulesType) => {
              const active = ClientAssignModuleData.find(
                (selectedModule: modulesType) => selectedModule?.code === module?.code,
              )
              return (
                <SubModuleCards
                  onClick={checkModuleStatus}
                  cardData={module}
                  includeButton={false}
                  isActive={Boolean(active)}
                  onlyName={true}
                  border={moduleId === module?.code}
                  backgroundimg={module?.logoImagePath}
                  onClickSubModule={(event: React.MouseEvent<HTMLButtonElement>) => {
                    handleOnClickSubModule(event, module)
                  }}
                  key={module.code}
                />
              )
            })
          ) : (
            <CenterStack>
              <StyledHTMLImg src={NoDataImage} width='45%' alt='no-data' />
            </CenterStack>
          )}
        </StyledStackAssignModule>
      </ColumnStack>
      {activeModule?.length !== 0 && moduleId && featurePermissionMap?.size > 0 && (
        <Box sx={headerGridBox(maxLength, featurePermissionMap?.size)}>
          <Box sx={[headerBoxSx, featureHeaderSx, featureHeaderBoxSx]}>
            <Checkbox
              size='small'
              onChange={handleParentbox}
              sx={parentCheckBoxSx}
              indeterminate={
                featureIds?.length > 0 && clientFeatureList?.length !== featureIds?.length
              }
              checked={clientFeatureList?.length === featureIds?.length}
            />
            <Typography>{t('features')}</Typography>
          </Box>
          <Box sx={[headerBoxSx, pesmissionsHeaderSx]} gridColumn={`${maxLength} span`}>
            <Typography>{t('sub_feature')}</Typography>
          </Box>
          {Array.from(featurePermissionMap).map(([key, value], i) => {
            const jsx = Array.from({ length: maxLength }).map((v: unknown, index) => {
              return value[index] ? (
                <Stack bgcolor={i % 2 === 0 ? Background : 'white'}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='small'
                        onChange={handleCheckbox}
                        id={value[index]?.id}
                        name={value[index]?.featureName}
                        checked={featureIds?.includes(value[index]?.id?.toString() ?? '')}
                      />
                    }
                    sx={checkboxLabelSx}
                    label={value[index]?.featureName}
                  />
                </Stack>
              ) : (
                <Stack bgcolor={i % 2 === 0 ? Background : 'white'}></Stack>
              )
            })
            return (
              <React.Fragment key={i}>
                <Stack justifyContent={'center'} bgcolor={i % 2 === 0 ? Background : 'white'}>
                  <Stack minWidth={'70%'}>
                    <Typography fontSize={'15px'}>{key}</Typography>
                  </Stack>
                </Stack>
                {jsx}
              </React.Fragment>
            )
          })}
        </Box>
      )}
    </AssignModulePaper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    allModules: moduleManagementEntity.getModules(state).getAllModules,
    ClientAssignModuleData:
      clientManagementEntity.getClientManagementData(state).clientAssignModules,
    clientFeatureList: clientManagementEntity.getClientManagementData(state).getClientFeatures,
    featureIdInfo: clientManagementEntity.getClientManagementData(state).featureIdInfo,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllModules: () => dispatch(getAllModules.request()),
    resetDeleted: () => dispatch(deleteModule.reset()),
    addModules: (data: modulesType[]) => dispatch(clientAssignModules.addModules(data)),
    resetModule: () => dispatch(clientAssignModules.resetModule()),
    mergSelectedModules: (data: modulesType[]) =>
      dispatch(getAssignedModules.mergAssignedModule(data)),
    resetSubModule: () => dispatch(getSubModule.reset()),
    getClientFeaturesList: (data: { [Key: string]: string }) =>
      dispatch(getClientFeatures.request(data)),
    setFeatureIdInfo: (data: string[]) => dispatch(featureIdInfo.setFeatureIds(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignModule)
