import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { SelectChangeEvent } from '@mui/material/Select'
import PropTypes from 'prop-types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageDropdownProps } from '../Types'
import { CustomSelect } from './CommonStyles'

const LanguageDropdown = ({ width, height }: LanguageDropdownProps) => {
  const [lang, setLang] = React.useState('en')
  const { i18n } = useTranslation()
  React.useEffect(() => {
    const language = localStorage.getItem('i18nextLng')
    language === 'es' && setLang(language as string)
  }, [])
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setLang(event.target.value as string)
    i18n.changeLanguage(event.target.value as string)
  }

  return (
    <Box width={width ? width : '100px'}>
      <FormControl fullWidth>
        <CustomSelect
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={lang}
          onChange={handleChange}
          height={height}
        >
          <MenuItem value={'en'}>English</MenuItem>
          <MenuItem value={'es'}>Spanish</MenuItem>
        </CustomSelect>
      </FormControl>
    </Box>
  )
}

LanguageDropdown.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}
export default LanguageDropdown
